
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import { Modal,Nav,Navbar,Button,Form,Container,Row,Col,Dropdown,NavDropdown} from 'react-bootstrap';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CloseIcon from '@mui/icons-material/Close';
import './moncss.css';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SecurityIcon from '@mui/icons-material/Security';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import packageJson from '../../package.json';
import { bubble as Menudashboard } from 'react-burger-menu'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SellIcon from '@mui/icons-material/Sell';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LyricsIcon from '@mui/icons-material/Lyrics';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EuroIcon from '@mui/icons-material/Euro';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SyncIcon from '@mui/icons-material/Sync';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

const data = [
  { name: 'Credit', value: 644 },
  { name: 'Consummed', value: 254 },
];

const COLORSCHART = ['white','#2A83E8'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

class Menu extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        connected : false,
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        listsound: [],
        persons: [],
        speakers: [],
        selectedValue: "",
        diarizer: [],
        nom: '',
        prenoms: '',
        mail: '',
        idtype:0,
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        speaker1: '',
        speaker2: '',
        idaudioselect: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        tokendeconnect: '',
        fullname: '',
        admin: '',
        manager: '',
        user: '',
        lng:'',
        jargonlist:'',
        titleglobalsentiment:'',
        showgestion:false,
        credituser:0.00,
        wbslink:'',
        imageprofillink:'',
        modallogout:false,
        loadingdata:'',
        typesubscription:'',
        creditpercent:'',
        barchartdata:[],
        piechartdata:[],
      };

      this.selectlangue = this.selectlangue.bind(this);
        
  }

  selectlangue(e) {

      this.setState({ lng: e.target.value });

      if(e.target.value === "FR"){
        this.setState({ lng:'FR'})
        sessionStorage.setItem( 'langueuser', 'FR');
      }else{
        this.setState({ lng:'EN'})
        sessionStorage.setItem( 'langueuser', 'EN');
      }

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("lng",e.target.value);

      let token = sessionStorage.getItem('tokenunsaid');
    
      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

      fetch(this.state.wbslink + `updateuserlangue`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ token},
        body: formData

        })

        .then(res => {

          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
      .then(data => {

        sessionStorage.setItem( 'langueuser', e.target.value);
        
        window.dispatchEvent(new Event("storage"));

        window.location.reload();
      
    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

  }

  showDropdown = (e)=>{
    this.setState({ show: true, setShow:true})
    
  }

  hideDropdown = e => {
    this.setState({ show: false, setShow:false})
}

showDropdowngestion = (e)=>{
  this.setState({ showgestion: true, setShow:true})
  
}

hideDropdowngestion = e => {
  this.setState({ showgestion: false, setShow:false})
}

clickdropdown(){
  if(this.state.showgestion == true){
    this.setState({ showgestion: false, setShow:false})
  }else{
    this.setState({ showgestion: true, setShow:true})
  }
}

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        if(JSON.stringify(data.rgpd).replace(/"/g, "") == "false"){

          if( window.location.pathname != "/Consent"){

            //alert(window.location.pathname)
            document.location.href = "/Consent";

          }
          
        }

        if(parseInt(JSON.stringify(data.active).replace(/"/g, "")) < 1){

          if(JSON.stringify(data.langue).replace(/"/g, "") == "FR"){

            alert("Votre compte a été désactiver. Veuillez contacter notre équipe commerciale pour plus d'informations.")

          }else{

            alert("Your account has been desactivated. Please contact our team for more information.")

          }
          
          sessionStorage.removeItem("tokenunsaid");

          document.location.href = "/login";
  
          return

        }


        if(parseInt(JSON.stringify(data.updateversion).replace(/"/g, "")) == 0){


          if(JSON.stringify(data.langue).replace(/"/g, "") == "FR"){

            alert("Une nouvelle fonctionnalité a été implémentée sur la plateforme, votre page va se rafraichir pour mettre à jour.");

          }else{

            alert("A new feature has been implemented on the platform, your page will refresh to update.");

          }

          fetch(wbslinkload + `updateversion`,{                  
          
            mode: 'cors',     
            method:'post',
            headers: {'Authorization': 'Bearer '+ tokencredit},      
            })
      
          .then(res => {
      
            errocode = res.status;
      
            if (!res.ok) {
                
                return res.json().then(json => { throw json; });
      
            }
      
            return res.json();
      
          })
          .then(data => {
      
            if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){
              
              window.location.reload();
              
              //alert("VERSION UPDATE")
      
            }
        
        })
        .catch(err => {
      
          if(errocode == 401 || errocode == 0){
            
            sessionStorage.removeItem("tokenunsaid");
            document.location.href = "/login";
      
            return
      
          }
      
          alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
      
          this.setState({
            loadershow: false
          })
      
        })
         
        }


        let typesub = JSON.stringify(data.subscription).replace(/"/g, "").split('-')[0];
        
        this.setState({
          credituser: parseFloat(JSON.stringify(data.credit).replace(/"/g, "")),
          imageprofillink: JSON.stringify(data.imageprofil).replace(/"/g, ""),
          lng: JSON.stringify(data.langue).replace(/"/g, ""),
          typesubscription: typesub,
          creditpercent: JSON.stringify(data.creditchart).replace(/"/g, "")+"min",
        })

        this.state.piechartdata.push({ 
          name: 'Databar', value:  parseFloat(JSON.stringify(data.creditchart).replace(/"/g, ""))
        });

        this.state.piechartdata.push({ 
          name: 'Databar', value: parseFloat(JSON.stringify(data.minutesmonth).replace(/"/g, ""))
        });

        this.state.barchartdata.push({ 
          name: 'Databar', Credit:  parseFloat(JSON.stringify(data.creditchart).replace(/"/g, "")), Consummed: parseFloat(JSON.stringify(data.minutesmonth).replace(/"/g, ""))
        });

        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){
          document.location.href = "/Buycredit";
        }

        this.setState({
          loadingdata:'dataload'
        })
        

    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

  }
    
   componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    //alert("IP:" + userdata[0]+", ID TYPE:" + userdata[1]+", ID USER:" + userdata[2]+", MAIL:" + userdata[4].replace(/tirait/g, '-' )+", LNG:" + userdata[5]+", NAME:" + userdata[6])

    {/** 
    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }
    */}

    if(parseInt(userdata[1])==2){
      this.setState({ admin: 'yes'})
    }else{
      this.setState({ admin: ''})
    }

    if(parseInt(userdata[1])<4){
      this.setState({ manager: 'yes'})
    }else{
      this.setState({ manager: ''})
    }

    if(parseInt(userdata[1])==4){
      this.setState({ user: 'yes'})
    }else{
      this.setState({ user: ''})
    }

    this.setState({ tokenvalue: token, connected:true})

    window.addEventListener("storage", this.showtoken);
    
  }

  changelangue (typelangue) {

    if(typelangue === "FR"){
      this.setState({ lng:'FR'})
      sessionStorage.setItem( 'langueuser', 'FR');
    }else{
      this.setState({ lng:'EN'})
      sessionStorage.setItem( 'langueuser', 'EN');
    }

     // Create an object of formData 
     const formData = new FormData(); 

     formData.append("lng",typelangue);

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `updateuserlangue`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

      sessionStorage.setItem( 'langueuser', typelangue);
      
      window.dispatchEvent(new Event("storage"));

      window.location.reload();
    
  })

  .catch(err => {
  
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

  }

  showtoken(){

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    //alert("IP:" + userdata[0]+", ID TYPE:" + userdata[1]+", ID USER:" + userdata[2]+", MAIL:" + userdata[4].replace(/tirait/g, '-' )+", LNG:" + userdata[5]+", NAME:" + userdata[6])


    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }

    if(parseInt(userdata[1])==2){
      this.setState({ admin: 'yes'})
    }else{
      this.setState({ admin: ''})
    }

    if(parseInt(userdata[1])<4){
      this.setState({ manager: 'yes'})
    }else{
      this.setState({ manager: ''})
    }

    if(parseInt(userdata[1])==4){
      this.setState({ user: 'yes'})
    }else{
      this.setState({ user: ''})
    }

    this.setState({ tokenvalue: token, connected:true})
      

  }
  asklogout(){
    this.setState({ modallogout: true})
  }

  logout (){

    this.setState({ 
      tokenvalue: '',
      connected:false,
      admin: '',
      manager: '',
      user: '',
      lng:'',
      modallogout:false,
    })

    sessionStorage.removeItem("token");

    window.dispatchEvent(new Event("storage"));
    //window.location.reload(true);
    document.location.href = '/login';

  };

  render() {

    const handleChange = (e) => {
      this.setState({selectedValue: e.target.value})
    }

          return (

            <div >

              {this.state.modallogout === true ?

              <Modal

              show={this.state.modallogout}
              onHide={ () => this.setState({modallogout:false})}
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                <div className="d-flex justify-content-end">
                  <button  className="btn btntransparent" onClick={() => this.setState({modallogout:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                </div>
                            
                <div className="d-flex flex-row justify-content-center">
                                  
                    <div className="d-flex flex-column" style={{margin:"20px"}}>
                        
                        <div className="d-flex flex-column">

                            <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous se déconnecter?' : 'Would you like to log out?'}</p>
                          
                            <form>

                            <div className='d-flex flex-row text-white align-items-center'>
                                        
                                  <Button className="btn"style={{ margin:"10px", width:'100px'}} onClick={ () => this.setState({modallogout:false})} variant="danger">{this.state.lng ==="FR" ? 'Non' : 'No'}</Button>
                                  <Button className="btn" style={{ margin:"10px",width:'100px'}} onClick={() => this.logout()} variant="primary">{this.state.lng ==="FR" ? 'Oui' : 'Yes'}</Button>

                            </div>
                            
                          </form>
                        </div>
                        
                    </div>

                </div>    

              </Modal.Body>
              </Modal>

              :

              null

              }

              {this.state.connected === true ?

                <div className="navbar-edit navbar-fixed-top menu w-100 ">

                  <Menudashboard Animation={'bubble'} width={ '350px' }>

                    <div style={{marginTop:"-20px"}}>
                      <img src={process.env.PUBLIC_URL + 'logo.png'} style={{width:"200px", height:"50px"}}/>
                      <p style={{textAlign:'center'}}>Version {packageJson.version}</p>
                    </div>


                    {this.state.tokenvalue && (

                    <Nav.Link href="/Dashboard" to="/Dashboard" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px",marginTop:"-10px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><DashboardIcon /></span>{this.state.lng ==="FR" ? 'Tableau de bord' : 'Dashboard'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Transcription" to="/Transcription" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><AudiotrackIcon /></span>Audios</Nav.Link>

                    )

                    }

                    {/** 
                    {this.state.admin && (

                    <Nav.Link href="/meetingreport" to="/meetingreport" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><SummarizeIcon /></span>{this.state.lng ==="FR" ? 'Faire un compte rendu' : 'Make a report'}</Nav.Link>

                    )

                    }
                    */}

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Buycredit" to="/Buycredit" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><EuroIcon /></span>{this.state.lng ==="FR" ? 'Abonnements' : 'Subscription'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Listpayment" to="/Listpayment" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><CreditScoreIcon /></span>{this.state.lng ==="FR" ? 'Liste des paiements' : 'List of payments'}</Nav.Link>

                    )

                    }

                    {this.state.admin && (
                      <Nav.Link onClick={() => this.clickdropdown()} onMouseEnter={this.showDropdowngestion} onMouseLeave={this.hideDropdowngestion} activeClass="active"  spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><AdminPanelSettingsIcon /></span>{this.state.lng ==="FR" ? 'Outils' : 'Tools'} {this.state.showgestion== false? <span><ArrowDropDownIcon /></span>: <span><ArrowDropUpIcon /></span>}
                                    
                        {this.state.showgestion== true?
                          
                          <div>
                            <Nav.Link  style={{fontSize:"18px"}} onClick={() => window.location.href='/Summarization'} href="/Summarization" to="/Summarization" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard' style={{marginRight:"10px"}}><SummarizeIcon /></span>{this.state.lng ==="FR" ? 'Résumé' : 'Summary'}</Nav.Link>
                            <Nav.Link style={{fontSize:"18px"}} onClick={() => window.location.href='/Telemarketing'} href="/Telemarketing" to="/Telemarketing" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard' style={{marginRight:"10px"}}><SellIcon /></span>{this.state.lng ==="FR" ? 'Extraction de donnée' : 'Data extraction'}</Nav.Link>
                            <Nav.Link style={{fontSize:"18px"}} onClick={() => window.location.href='/Keyword'} href="/Keyword" to="/Keyword" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard' style={{marginRight:"10px"}}><SpellcheckIcon /></span>{this.state.lng ==="FR" ? 'Mot clé' : 'Keyword'}</Nav.Link>
                            <Nav.Link style={{fontSize:"18px"}} onClick={() => window.location.href='/Sentimentanalysis'} href="/Sentimentanalysis" to="/Sentimentanalysis" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard' style={{marginRight:"10px"}}><MonitorHeartIcon /></span>{this.state.lng ==="FR" ? 'Analyse de sentiment' : 'Sentiment analysis'}</Nav.Link>
                            <Nav.Link style={{fontSize:"18px"}} onClick={() => window.location.href='/Newversion'} href="/Newversion" to="/Newversion" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} ><span className='icondahsboard' style={{marginRight:"10px"}}><SyncIcon /></span>{this.state.lng ==="FR" ? 'Nouvelle version' : 'New version'}</Nav.Link>
                          </div>
                          :

                          null
                        }  
                      
                      </Nav.Link>
                    )

                    }
      
                    {/*
                    {this.state.admin && (

                    <Nav.Link href="/Summarization" to="/Summarization" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><SummarizeIcon /></span>{this.state.lng ==="FR" ? 'Outils de résumé' : 'Summary tool'}</Nav.Link>

                    )

                    }  
                    */}    

                    {this.state.admin && (

                    <Nav.Link href="/Dataextraction" to="/Dataextraction" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><BrowserUpdatedIcon /></span>{this.state.lng ==="FR" ? 'Personnaliser la fiche résumé' : 'Personalize summary sheets'}</Nav.Link>

                    )

                    }  

                    {/*
                    {this.state.admin && (

                    <Nav.Link href="/Telemarketing" to="/Telemarketing" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><SellIcon /></span>{this.state.lng ==="FR" ? 'Extraction de donnée' : 'Data extraction tool'}</Nav.Link>

                    )

                    }
                    */} 
                    {/*

                    {this.state.admin && (

                    <Nav.Link href="/Keyword" to="/Keyword" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><SpellcheckIcon /></span>{this.state.lng ==="FR" ? 'Outils de mot clé' : 'Keyword tool'}</Nav.Link>

                    )

                    } 
                    */} 

                    {/*
                    {this.state.tokenvalue && (

                    <Nav.Link href="/Globalsentimentville" to="/Globalsentimentville" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><FavoriteIcon /></span>{this.state.lng ==="FR" ? 'Global sentiment' : 'Global sentiment'}</Nav.Link>

                    )

                    }
                    */} 

                    {this.state.admin && (

                    <Nav.Link href="/Utilisateurs" to="/Utilisateurs" style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><GroupIcon /></span>{this.state.lng ==="FR" ? 'Utilisateurs' : 'Users'}</Nav.Link>

                    )

                    }

                    {this.state.manager && (

                    <Nav.Link href="/Society" to="/Society" style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><RoomPreferencesIcon /></span>{this.state.lng ==="FR" ? 'Nos sites' : 'Our sites'}</Nav.Link>

                    )

                    }

                    {this.state.admin && (

                    <Nav.Link href="/Entreprise" to="/Entreprise" style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><LocationCityIcon /></span>{this.state.lng ==="FR" ? 'Entreprises' : 'Enterprises'}</Nav.Link>

                    )

                    }  

                    {/**
                    {this.state.admin && (
                    <Nav.Link  spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><AdminPanelSettingsIcon />{this.state.lng ==="FR" ? 'Outils' : 'Tools'}</span>
                    <NavDropdown id="collasible-nav-dropdown" show={this.state.showgestion}
                    onMouseEnter={this.showDropdowngestion} 
                    onMouseLeave={this.hideDropdowngestion}
                    renderMenuOnMount={true}
                    >
                      
                    <NavDropdown.Item href="/Utilisateurs" to="/Utilisateurs"><span className='icondahsboard'><GroupIcon /></span>{this.state.lng ==="FR" ? 'Utilisateurs' : 'Users'}</NavDropdown.Item>
                    <NavDropdown.Item href="/Society" to="/Society"><span className='icondahsboard'><RoomPreferencesIcon /></span>{this.state.lng ==="FR" ? 'Nos sites' : 'Our sites'}</NavDropdown.Item>
                    <NavDropdown.Item href="/Entreprise" to="/Entreprise"><span className='icondahsboard'><LocationCityIcon /></span>{this.state.lng ==="FR" ? 'Entreprises' : 'Enterprises'}</NavDropdown.Item>

                    <NavDropdown.Item href="/Globalsentiment" to="/Globalsentiment">{this.state.lng ==="FR" ? 'Sentiment par agent' : 'Global sentiment per agent'}</NavDropdown.Item>
                    </NavDropdown>
                    </Nav.Link>

                    )

                    }
                     */}

                    

                    {this.state.admin && (

                    <Nav.Link href="/Jargon" to="/Jargon" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><LyricsIcon /></span>{this.state.lng ==="FR" ? 'Jargon' : 'Jargon'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Map" to="/Map" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><EditLocationIcon /></span>{this.state.lng ==="FR" ? 'Carte' : 'Map'}</Nav.Link>

                    )

                    }


                    {this.state.manager && (

                    <Nav.Link href="/Trash" to="/Trash" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><DeleteForeverIcon /></span>{this.state.lng ==="FR" ? 'Corbeille' : 'Trash'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Support" to="/Support" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><SupportAgentIcon /></span>{this.state.lng ==="FR" ? 'Support' : 'Support'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Consent" to="/Consent" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><AssignmentIcon /></span>{this.state.lng ==="FR" ? 'Consentement' : 'Consent'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Confidentiality" to="/Confidentiality" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><AssuredWorkloadIcon /></span>{this.state.lng ==="FR" ? 'Politique de confidentialité' : 'Privacy policy'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Terms-of-services" to="/Terms-of-services" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><DesignServicesIcon /></span>{this.state.lng ==="FR" ? 'Conditions générales de services' : 'Terms of services'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Terms-of-uses" to="/Terms-of-uses" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><FolderSharedIcon /></span>{this.state.lng ==="FR" ? 'Conditions d\'utilisation' : 'Terms of uses'}</Nav.Link>

                    )

                    }

                    {this.state.tokenvalue && (

                    <Nav.Link href="/Legal-notice" to="/Legal-notice" activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginLeft:"-40px"}}><span className='icondahsboard' style={{marginRight:"10px"}}><PrivacyTipIcon /></span>{this.state.lng ==="FR" ? 'Informations légales' : 'Legal notice'}</Nav.Link>

                    )

                    }

                    {isMobile?
                        
                        <div className='d-flex flex-row justify-content-center' >

                          <Nav.Link onClick={() => this.changelangue('FR')}><img src={process.env.PUBLIC_URL +'fr.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>
                          <Nav.Link onClick={() => this.changelangue('EN')}><img src={process.env.PUBLIC_URL +'eu.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>

                        </div>
                          

                        :
                          
                          null
                      
                      }

                    {/*
                    {this.state.tokenvalue && (

                      <Nav.Link href="/Deconnexion" to="/Deconnexion" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><PowerSettingsNewIcon /></span>{this.state.lng ==="FR" ? 'Se déconnecter' : 'Log out'}</Nav.Link>

                    )

                    }
                    

                    {this.state.tokendeconnect && (

                      <Nav.Link href="/Login" to="/Login" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}>{this.state.lng ==="FR" ? 'Se connecter' : 'Log in'}</Nav.Link>

                      )

                    }

                    

                    {this.state.signinvalide && (

                      <Nav.Link href="/Inscription" to="/Inscription" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}><span className='icondahsboard'><PersonAddIcon /></span>{this.state.lng ==="FR" ? 'Inscrivez-vous gratuitement' : 'Sign up for free'}</Nav.Link>

                      )

                    }

                    

                    {this.state.tokenvalue && (

                      <div className='d-flex flex-row justify-content-center' >

                          <Nav.Link onClick={() => this.changelangue('FR')}><img src={process.env.PUBLIC_URL +'fr.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>
                          <Nav.Link onClick={() => this.changelangue('EN')}><img src={process.env.PUBLIC_URL +'eu.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>

                      </div>

                    )

                    }
                    */}
                    
                    
                   

                </Menudashboard>

                {this.state.loadingdata == "dataload" ?

                    

                      <div className='d-flex flex-row justify-content-end' >

                          

                        

                        {isBrowser?

                        <p style={{marginTop:"14px",color:"#2A83E8",fontSize:"22px", fontWeight:"bold"}}>{this.state.typesubscription.charAt(0).toUpperCase() + this.state.typesubscription.slice(1)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                        :

                        <p style={{marginTop:"20px", paddingRight:"20px",color:"#2A83E8"}}>{this.state.typesubscription.charAt(0).toUpperCase() + this.state.typesubscription.slice(1)}</p>

                        }

                        {isBrowser?

                          <div className='d-flex flex-row'>

                            <p style={{marginTop:"14px",marginLeft:"0px", fontWeight:"bold",fontSize:"22px"}}> &nbsp;{this.state.lng ==="FR" ? 'Disponible:' : 'Available:'} &nbsp;{parseInt(JSON.stringify(this.state.barchartdata[0]['Credit']))} min</p>
                            <p style={{marginTop:"20px",marginLeft:"0px",color:"#2A83E8", fontWeight:"bold"}}> &nbsp; / {parseInt(JSON.stringify(this.state.barchartdata[0]['Consummed']))} min ({this.state.lng ==="FR" ? 'Total mois' : 'Total month'})</p>

                          </div>

                          :

                          <div className='d-flex flex-row'>

                            <p style={{marginTop:"20px",marginLeft:"0px", fontWeight:"bold",fontSize:"16px"}}> &nbsp;{parseInt(JSON.stringify(this.state.barchartdata[0]['Credit']))}</p>
                            <p style={{marginTop:"20px",marginLeft:"0px",color:"#2A83E8"}}> &nbsp; / {parseInt(JSON.stringify(this.state.barchartdata[0]['Consummed']))} min</p>

                          </div>

                        }
                        
                        {/*<p style={{marginTop:"10px", color:'#2A83E8'}}>{this.state.lng ==="FR" ? 'Crédit:' : 'Credit:'} &nbsp;</p>*/}

                        {/*<p style={{marginTop:"10px", color:this.state.credituser>4.99 ? 'white':'#dc3545'}}>{this.state.credituser.toLocaleString()} €</p>*/}

                        <Nav.Link href="/Profil" to="/Profil" activeClass="active" spy={true} smooth={true} offset={-0} duration={500}>{this.state.imageprofillink == 'null' ? <span><PersonOutlineIcon/></span> : <img src={this.state.wbslink + 'images/profil/' + this.state.imageprofillink}  style={{width:"30px", height:"30px", borderRadius:"30px", marginLeft:"5px",marginTop:"10px"}}/>}</Nav.Link>
                        

                        {isBrowser?
                        
                        <div className='d-flex flex-row justify-content-end' style={{marginTop:"0px"}}>

                          <Nav.Link onClick={() => this.changelangue('FR')}><img src={process.env.PUBLIC_URL +'fr.png'} style={{marginTop:"15px",width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>
                      
                          <Nav.Link onClick={() => this.changelangue('EN')}><img src={process.env.PUBLIC_URL +'eu.png'} style={{marginTop:"15px",width:"30px", height:"20px"}} alt="Unsaid"/></Nav.Link>

                         {/** <p style={{marginTop:"20px", color:'white'}}>{this.state.lng ==="FR" ? 'Disponible:' : 'Available:'} &nbsp;</p>*/} 

                        </div>
                          

                        :
                          
                          null
                      
                      }

                      <Nav.Link onClick={() => this.asklogout()} activeClass="active" spy={true} smooth={true} offset={-0} duration={500} style={{marginTop:"10px"}}><span className='icondahsboard'><PowerSettingsNewIcon /></span></Nav.Link>

                      </div>


                    :

                    null

                }

                </div>
              
                :

                null

              }        
    
            </div>
               
          );
        }
    
}

export default Menu;
