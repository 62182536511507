
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import '../App.css';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isBrowser } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { PieChart, Pie,  Cell,Legend } from "recharts";

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const data = [
  { name: 'Positive', value: 644 },
  { name: 'Neutral', value: 254 },
  { name: 'Negative', value: 103 },
];

var errocode = 0;


class Sentimentanalysis extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        question:'',
        sentence:'',
        responsesentiment:[],
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        lng:'',
        errorform: false,
        errormessage: '',
        
      };

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.state.responsesentiment.length = 0;

    {/*
    this.state.responsesentiment.push({ 
      name: 'Positif', value: 57.00,
    });
    this.state.responsesentiment.push({ 
        name: 'Neutre', value: 22.6,
    });
    this.state.responsesentiment.push({ 
      name: 'Négatif', value: 21.4,
    });

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    */}
    
    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  componentDidMount() { 

    if(this.state.lng === 'FR'){

      document.title = "Analyse de sentiment";
    
    }else{

      document.title = "Sentiment analysis";

    }

  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}

 senddata() {

  this.state.responsesentiment.length = 0;

  this.setState({
    loadershow:true,
  })

  if(this.state.sentence.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la conversation.',loadershow:false });
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add conversation.',loadershow:false });
    return
  
  }

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("sentence",this.state.sentence);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired === true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

  fetch(this.state.wbslink + `sentimenttools`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

    this.state.responsesentiment.push({ 
      name: 'Positif', value: parseFloat(JSON.stringify(data.positive).replaceAll(/"/g, ""))*100,
    });
    this.state.responsesentiment.push({ 
        name: 'Neutre', value: parseFloat(JSON.stringify(data.neutral).replaceAll(/"/g, ""))*100,
    });
    this.state.responsesentiment.push({ 
      name: 'Négatif', value: parseFloat(JSON.stringify(data.negative).replaceAll(/"/g, ""))*100,
    });


    this.setState({
      errorform: false,
      errormessage: '',
      loadershow:false,
      sentence : this.state.sentence.replaceAll('\n', ' '),   
    })  

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

  })
  .catch(err => {
    
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }
    
    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

   
  render() {

          return (
              
                <div className="App" style={{margin:'10%',marginTop:'60px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                        <div className='d-flex justify-content-start'>
                          <label style={{marginTop:isBrowser?"40px":"20px"}}>{this.state.lng ==="FR" ? 'Phrase' : 'Sentence'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={3} className="form-control" value={this.state.sentence} onChange={(e) => this.setState({ sentence: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform === true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                     

                      <div className='d-flex justify-content-center'>
     
                        <button style={{margin:'20px'}}onClick={() => this.senddata()} className="btn btn-primary">{this.state.lng ==="FR" ? 'Analyser' : 'Analyse'}</button> 
                                                             
                      </div> 

                      <div className='d-flex justify-content-center'>

                          {this.state.loadershow === true ?
                                

                              <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={50} />
                                

                              :

                              null

                          }   

                      </div> 

                          {this.state.responsesentiment.length>1?

                            <div className='d-flex justify-content-center'>
                            
                              <PieChart width={600} height={400}>
                                <Pie
                                  data={Object.values(this.state.responsesentiment)}
                                  cx="50%"
                                  cy="50%"
                                  labelLine={true}
                                  label={renderCustomizedLabel}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  dataKey="value"

                                >
                                  {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                  ))}
                                </Pie>
                                <Legend iconType="circle"  wrapperStyle={{ top: 20 }}/>
                              </PieChart>
                            
                            </div>

                            :

                            null
                        
                        }
                      
                </div>
                
          );
        }
    
}

export default Sentimentanalysis;
