import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import queryString from 'query-string';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import './moncss.css';
import { Button,Form,Modal} from 'react-bootstrap';
import {  BarChart, Bar,Label, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip} from "recharts";
import ReactToPrint from 'react-to-print';
import Loader from "react-js-loader";
import logo from './logotransparent.png';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser} from 'react-device-detect';
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


var listdata=[];

var dateselectdu = new Date();
var dateselectau = new Date();

var errocode = 0;

const data = [
  { name: 'Positive', value: 644 },
  { name: 'Neutral', value: 254 },
  { name: 'Negative', value: 103 },
];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

var numberspeaker = []

class Transcription extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        soundfilename:null,
        dureesound:'',
        selectedOption: null,
        listsoundinit: [],
        listsound: [],
        listlng: [],
        persons: [],
        speakers: [],
        speaker1: [],
        speaker1sentiment: [],
        speaker2: [],
        speaker2sentiment: [],
        speakernegatif: 0.00,
        speakerneutre: 0.00,
        speakerpositif: 0.00,
        selectedValue: "",
        diarizer: [],
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        isModalupdate: false,
        isModaladd: false,
        isModaldelete:false,
        idaudioselect: 0,
        idaudioselectprint: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        username: '',
        soundlink:'',
        soundname:'',
        soundlng:'',
        summarization:'',
        speaker1name:'',
        speaker2name:'',
        loadershow: true,
        textloading:'',
        lng: '',
        isModaldetails: false,
        isModaltranscription: false,
        wbslink:'',
        dataextract:'',
        comment:'',
        commentbutton:false,
        speakerbutton:false,
        thematiquebutton:false,
        printbutton:false,
        soundnamebutton:false,
        datedu : '',
        dateau: '',
        idsoc:0,
        iduser:0,
        idtypeuser:0,
        filterdata:'',
        offset: 0,
        listsoundpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
        typesubscription:'',
        typetranscription:'',
        speakernumber:2,
      };

      this.selectlng = this.selectlng.bind(this);
      this.selecttypetranscription = this.selecttypetranscription.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
      this.selectnumber = this.selectnumber.bind(this);
        
  }


  //CLICK SUR LE NUMERO DE PAGE
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listsoundpage)
    });

};


//CHARGEMENT DES DONNES APRE CLICK SUR LA PAGE
  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listsound:slice
  })

  window.scrollTo(0, 0)

  }

  loadpage(slice){
    this.setState({
      listsound:slice
      
    })
    
  }
 
  componentWillMount() {

    sessionStorage.removeItem( 'iconify-count');
    sessionStorage.removeItem( 'iconify-version');

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    const datauser = JSON.stringify(myDecodedToken.init).replace(/"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }

    this.setState({
      iduser:parseInt(userdata[2]),
      idtypeuser:parseInt(userdata[1])
    })

    var datevalue = new Date();
    dateselectdu = new Date(datevalue);

    var moisdu = '';

    if(parseInt(datevalue.getMonth())<9){
      moisdu = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisdu = (parseInt(datevalue.getMonth()) + 1)
    }

    var jourdu = '';
    if(parseInt(datevalue.getDate())<9){
      jourdu = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourdu = (parseInt(datevalue.getDate()))
    }
    var datedu = datevalue.getFullYear()  + '-' + moisdu + '-' + jourdu ;
    
    this.setState({ datedu: datedu})

    dateselectau = new Date(datevalue);

    var moisau = '';

    if(parseInt(datevalue.getMonth())<9){
      moisau = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisau = (parseInt(datevalue.getMonth()) + 1)
    }

    var jourau = '';
    if(parseInt(datevalue.getDate())<9){
      jourau = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourau = (parseInt(datevalue.getDate()))
    }
    var dateau = datevalue.getFullYear()  + '-' + moisau + '-' + jourau ;
    
    this.setState({ dateau: dateau})

    window.addEventListener("keydown", this.handleKeypress);

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let tokenuser = sessionStorage.getItem('tokenunsaid');

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {
        
        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          let lastnumber = 21;

          if(JSON.stringify(data.subscription).replace(/"/g, "") === "free")  {
            lastnumber = 3;
          } 

          if(JSON.stringify(data.subscription).replace(/"/g, "").split('-')[0] === "basic")  {
            lastnumber = 9;
          } 

          for (let i = 2; i<lastnumber; i++){
            numberspeaker.push({id:i,value:i})
          }  

          if(JSON.stringify(data.subscription).replace(/"/g, "") == "free"){

            this.setState({           
              typetranscription:"compterendu",
              speakernumber:2,
            })
  
          }

          this.setState({
            typesubscription:JSON.stringify(data.subscription).replace(/"/g, ""),
            loadingdata:false
          })
          
        }
        
    })

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    if(tokenuser === '' || tokenuser === 'Undefined'){
      this.setState({ tokenexist: false})
      return
    }else{

      this.setState({ tokenvalue: tokenuser,username: '', lng:lnguser})

      //this.reloaddata();
      
    }

    

  }


  //SELECTION DATE DU
  selectdatedu = (datevalue) =>{

    dateselectdu = new Date(datevalue);
  
    var moisdu = '';
  
    if(parseInt(datevalue.getMonth())<9){
      moisdu = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisdu = (parseInt(datevalue.getMonth()) + 1)
    }
  
    var jourdu = '';
    if(parseInt(datevalue.getDate())<9){
      jourdu = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourdu = (parseInt(datevalue.getDate()))
    }
    var datedu = datevalue.getFullYear()  + '-' + moisdu + '-' + jourdu ;
    
      this.setState({ datedu: datedu})
  
  }
  

  //SELECTION DATE AU
  selectdateau = (datevalue) =>{
  
    dateselectau = new Date(datevalue);
  
    var moisau = '';
  
    if(parseInt(datevalue.getMonth())<9){
      moisau = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisau = (parseInt(datevalue.getMonth()) + 1)
    }
  
    var jourau = '';
    if(parseInt(datevalue.getDate())<9){
      jourau = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourau = (parseInt(datevalue.getDate()))
    }
    var dateau = datevalue.getFullYear()  + '-' + moisau + '-' + jourau ;
    
      this.setState({ dateau: dateau})
  
  }


  //LIST AUDIO ENTRE LES DEUX DATES
  async selectlistdate() {

      if (this.state.datedu == "") {
    
        this.setState({ errorform: true, errormessage: 'Date de début invalide.' });
                          
        return;
    
      }
    
      if (this.state.dateau == "") {
    
        this.setState({ errorform: true, errormessage: 'Date de fin invalide.' });
                          
        return;
    
      }

      this.setState({ idaudioselect: 0,filterdata:'' });

      let token = sessionStorage.getItem('tokenunsaid');
      
      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "") 

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("from",this.state.datedu);  
      formData.append("to",this.state.dateau);  

      const response = await fetch(this.state.wbslink + 'listaudiobydate',{                       
        mode:'cors',
        method:'post',
        headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
        body: formData
        });
      const data = await response.json();

      if(data.length > 0){
        this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsoundinit :data,
            listsound:slice
        })

      }else{
        
        this.setState({ listsound: [], listsoundinit: [],listsoundpage:[],loadershow: false})

      }
    
  }; 

  //RECHARGER LA PAGE
  reloaddata () {

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
    }else{
      document.title = "Audio list";
    }

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
  
  async componentDidMount() { 

    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "") 

    const response = await fetch(this.state.wbslink + 'audio',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/"/g, "") !== '0'){
      this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsoundinit :data,
          listsound:slice
      })

    }else{
      this.setState({ loadershow: false})
    }

    
    const responselngsnd = await fetch(this.state.wbslink + 'listsoundlanguage',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });

    const datalngsnd = await responselngsnd.json();
    this.setState({ listlng: datalngsnd, loadershow: false})

    if(lnguser === "FR"){
      document.title = "Liste des audios";
    }else{
      document.title = "Audio list";
    }

}

async refreshlistsound() { 

    listdata.length = 0;

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "") 

    this.setState({ idaudioselect: 0,filterdata:'' });

    const response = await fetch(this.state.wbslink + 'audio',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/"/g, "") !== '0'){
      this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsoundinit :data,
          listsound:slice
      })

    }else{
      this.setState({ loadershow: false})
    }

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
      this.setState({ loadershow: false})
    }else{
      document.title = "Audio list";
      this.setState({ loadershow: false})
    }

    this.loaderdesactive();

}


//LISTE DES AUDIOS A L'OUVERTURE DE LA PAGE
async selectlistaudio() { 

  listdata.length = 0;

  let token = sessionStorage.getItem('tokenunsaid');
  
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "") 

  this.setState({ idaudioselect: 0,filterdata:'' });

  const response = await fetch(this.state.wbslink + 'listaudio',{                       
    mode:'cors',
    method:'post',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
    });
  const data = await response.json();

  if(JSON.stringify(data).replace(/"/g, "") !== '0'){
    this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

    data.forEach((item)=>{
      listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript,typetranscription:item.typetranscription,status:item.status});
    })
  
    var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    
    this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        listsoundinit :data,
        listsound:slice
    })

  }else{
    this.setState({ loadershow: false})
  }

  if(this.state.lng === "FR"){
    document.title = "Liste des audios";
    this.setState({ loadershow: false})
  }else{
    document.title = "Audio list";
    this.setState({ loadershow: false})
  }

  this.loaderdesactive();

}

    handleChange = selectedOption => {
      this.setState({ selectedOption });
      //alert(`Option selected:`, selectedOption);
    };


    //SELETION ET TEST TAILLE FICHIER AUDIO
    onFileChange = event => { 

      const file = event.target.files[0]
      const size = file.size
      const sizemax = 100 * 1000 * 1024;
      
      if (size > sizemax) {

        if(this.state.lng === "FR"){
          alert("Le fichier dépasse 100 Mo." + "\n" + "Veuillez réduire la taille du fichier.")
          return
        }else{
          alert("The file exceeds 100 MB." + "\n" + "Please reduce the file size.")
          return
        }
        
      }
      

      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
      if(event.target.files[0] !== null){
        this.setState({
          loadershow: false,
          soundfilename : event.target.files[0].name,
        });
      }

    }; 
     
    // ENVOIE DU FICHIER VERS LE SERVEUR
    onFileUpload = () => { 

      if(this.state.selectedFile === null  && this.state.lng === "FR"){

          this.setState({
            loadershow: false,
            textloading:'Veuillez séléctionner un fichier audio.'
          });

        alert('Veuillez séléctionner un fichier audio.')
        
        return;
      
      }

      if(this.state.selectedFile === null && this.state.lng !== "FR"){

        this.setState({
          loadershow: false,
          textloading:'Please select an audio file.'
        });

        alert('Please select an audio file.')
      
      return;
    
    }

      if(this.state.soundlng ==='' && this.state.lng === "FR"){

          this.setState({
            loadershow: false,
            textloading:'Veuillez séléctionner la language de l\'audio.'
          });

          alert('Veuillez séléctionner la language de l\'audio.')
        
        return;

      }

      if(this.state.soundlng ==='' && this.state.lng !== "FR"){

          this.setState({
            loadershow: false,
            textloading:'Please select audio language.'
          });

          alert('Please select audio language.')
        
        return;

      }


    if(this.state.typetranscription ==='' && this.state.lng === "FR" && parseInt(this.state.iduser)<11){

        this.setState({
          loadershow: false,
          textloading:'Veuillez séléctionner le type de transcription.'
        });

        alert('Veuillez séléctionner le type de transcription.')
      
      return;

    }

    if(this.state.typetranscription ==='' && this.state.lng !== "FR"){

        this.setState({
          loadershow: false,
          textloading:'Veuillez sélectionner le type de transcription.'
        });

        alert('Veuillez sélectionner le type de transcription.')
      
      return;

    }

    if(this.state.typetranscription ==='' && this.state.lng !== "EN"){

      this.setState({
        loadershow: false,
        textloading:'Please select transcription type.'
      });

      alert('Please select transcription type.')
    
    return;

  }

      if(this.state.lng === "FR"){
        this.setState({
          loadershow: true,
          textloading:'Chargement du fichier en cours ...'
        });
      }else{
        this.setState({
          loadershow: true,
          textloading:'Loading data file ...'
        });
      }

      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "sound", 
        this.state.selectedFile, 
        this.state.selectedFile.name,
      ); 

      //formData.append("iduser",iduserupload);
      //formData.append("idsoc",idusersociety);
      formData.append("lngsound",this.state.soundlng);
      formData.append("interlocutornumber",this.state.speakernumber);
      
      {/* 
      if(parseInt(this.state.iduser)>10){
        formData.append("typetranscription","compterendu");
      }else{
        formData.append("typetranscription",this.state.typetranscription);
      }
      */}
      
      formData.append("typetranscription",this.state.typetranscription);
      if(this.state.typetranscription =="sentiment"){
        formData.append("interlocutornumber",2);
      }
      
      let token = sessionStorage.getItem('tokenunsaid');
    
      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

      fetch(this.state.wbslink + `audio`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ token},
        body: formData

        })

        .then(res => {

          errocode = res.status;

          if(errocode == 401 || errocode == 0){

            this.setState({
              loadershow: false
            })

            if(this.state.lng === "FR"){

              alert("Erreur :" + errocode + ", "+"Utilisateur non autorisé, veuillez vous connecter.")

            }else{

              alert("Erreur :" + errocode + ", "+"User not authorized, please reconnect.")

            }

            sessionStorage.removeItem("tokenunsaid");
            document.location.href = "/login";
    
            return;
    
          }

          if(errocode == 400){

            this.setState({
              loadershow: false
            })

            if(this.state.lng === "FR"){

              alert("Erreur :" + errocode + ", "+"Le format de fichier n'est pas pris en charge." + "\n" + " Formats acceptés: mp3, MP3, wav, WAV, gsm, GSM, flac, FLAC, aifc, AIFC, aiff, AIFF, m4a, M4A, mp2, MP2, ogg, OGG, mpg, MPG, mpeg, MPEG, mp4, MP4, mov, MOV, avi, AVI." + "\n" + "Veuillez convertir le fichier dans un format pris en charge.")

            }else{

              alert("Erreur :" + errocode + ", "+"Le format de fichier n'est pas pris en charge." + "\n" + "Accepted formats: mp3, MP3, wav, WAV, gsm, GSM, flac, FLAC, aifc, AIFC, aiff, AIFF, m4a, M4A, mp2, MP2, ogg, OGG, mpg, MPG, mpeg, MPEG, mp4, MP4, mov, MOV, avi, AVI."+ "\n" + "Convert the file to a supported format.")

            }

            return;

          }

          if(errocode == 402){

            this.setState({
              loadershow: false
            })

            var limitaudioduration = 60;

            if(this.state.typesubscription === "free"){

              limitaudioduration = 15;

            }

            if(this.state.typesubscription != "free" && this.state.typesubscription != "enterprise"){

              limitaudioduration = 60;

            }

            if(this.state.lng === "FR"){

              alert("Erreur :" + errocode + ", "+"Le fichier dépasse " + limitaudioduration + " minutes." + "\n" + "Veuillez réduire la durée du fichier audio.")

            }else{

              alert("Erreur :" + errocode + ", "+"The file exceeds " + limitaudioduration + " minutes." + "\n" + "Please reduce the duration of the audio file.")

            }

            return;

          }

          if(errocode == 405){

            this.setState({
              loadershow: false
            })

            if(this.state.lng === "FR"){

              alert("Erreur :" + errocode + ", "+"Le nombre d\'interlocuteur dépasse le maximum." + "\n" + "Veuillez le réduire.")

            }else{

              alert("Erreur :" + errocode + ", "+"The interlocutors number are too many." + "\n" + "Please reduce it.")

            }

            return;

          }
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {
    
        if(data.length > 0){
          
          listdata.length = 0;
    
          this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript,typetranscription:item.typetranscription,status:item.status});
          })
        
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listsoundinit :data,
              listsound:slice,
              isModaladd:false,
              soundlng:'',
              selectedFile: null,
              soundfilename:null,
              speakernumber:2,
          })
    
        }else{
    
          this.setState({ listsound : data,listsoundinit: data});

          this.loaderdesactive();
    
        }

      })

      .catch(err => {
        
        /*
        if(errocode == 401 || errocode == 0){
          
          sessionStorage.removeItem("tokenunsaid");
          document.location.href = "/login";
  
          return
  
        }
  
        alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
        

        this.setState({
          loadershow: false
        })
        */

  
      });  

     
      // Details of the uploaded file 
     // console.log(this.state.selectedFile); 

     
      // Request made to the backend api 
      // Send formData object 

      {/** 
      axios.post(this.state.wbslink + "audio/addfile.php", formData)
      .then(res => {
    
        //alert(JSON.stringify(res.data));
        this.setState({ listsound : res.data,listsoundinit: res.data});

        this.loaderdesactive();

        */}

  }


  //DESACTIVER LE CHARGEMENT
  loaderdesactive(){

    this.setState({ loadershow: false, selectedFile:'', soundlng:'', isModaladd:false, isModaltranscription:false, idaudioselect:0,comment:""});

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
    }else{
      document.title = "Audio list";
    }
    
  }

    fileData = () => { 
      
      if (this.state.selectedFile) { 

    var file = this.state.selectedFile;
    
    var reader = new FileReader();

    reader.onload = function (event) {

        var audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(event.target.result, function(buffer) {
            var duration = parseInt(buffer.duration);

            var h = Math.floor(duration / 3600);
            var m = Math.floor(duration % 3600 / 60);
            var s = Math.floor(duration % 3600 % 60);
        
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            var dureetotal = "La durée de l'audio est de " + hDisplay + mDisplay + sDisplay + " secondes.";
            //this.setState({ dureesound: dureetotal });
            
            //alert(dureetotal);

        });
    };

    reader.onerror = function (event) {
        console.error("An error ocurred reading the file: ", event);
    };

    reader.readAsArrayBuffer(file);
          
        return ( 
          <div> 
            <p>Nom du fichier: {this.state.selectedFile.name}</p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Veuillez choisir un fichier audio.</h4> 
          </div> 
        ); 
      } 
    }; 


    //TRANSCRIRE L'AUDIO
    audiosend (linksound,nomsound,timesound,lgnsound,typetranscription) {
 
    

      if(this.state.lng === "FR"){
        this.setState({

          textloading:'Transcription en cours ...',
        
        });
      }else{
        this.setState({

          textloading:'Loading transcription ...',
        
        });
      }

      this.setState({
        
        loadershow: true,
        soundlink: this.state.wbslink + "sound/" + linksound,
        soundname : nomsound,
        idaudioselect : nomsound,
        isModaltranscription: true
      
      });

      let token = sessionStorage.getItem('tokenunsaid');

      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

      var lientranscription = "";

      if(typetranscription === "compterendu"){
        lientranscription = this.state.wbslink + `meetingreporttools`;
      }else{
        lientranscription = this.state.wbslink + `transcripttools`;
      }

      /*
      if(parseInt(this.state.iduser)>10){
        lientranscription = this.state.wbslink + `meetingreporttools`;
      }
      */
      

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("idaudio",linksound);

      fetch(lientranscription,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ token},
        body: formData

        })

        .then(res => {

          errocode = res.status;

          if (!res.ok) {
              
              return res.json().then(json => { throw json; });

          }

          return res.json();

        })
      .then(data => {

        this.setState({
          soundlink: '',
          soundname : '',
          isModaltranscription: false,
          loadershow: false
        })
        
        window.location.reload();

      
        {/** 
      if(data.length > 0){

        this.setState({
          soundlink: '',
          soundname : '',
          isModaltranscription: false,
          loadershow: false
      })
        
       
        
        //this.refreshlistsound()

        }else{

          this.setState({ listdata : [],idsociete: 0, question:''});

        }

        
      */}

      })
      .catch(err => {
      
        if(errocode == 401 || errocode == 0){
          
          sessionStorage.removeItem("tokenunsaid");
          document.location.href = "/login";

          return

        }

        if(errocode == 200 ){
          
          this.setState({
            soundlink: '',
            soundname : '',
            isModaltranscription: false,
            loadershow: false
          })
          
          window.location.reload();

          return

        }

        if(errocode == 403 ){

          this.setState({
            soundlink: '',
            soundname : '',
            isModaltranscription: false,
            loadershow: false
          })

          if(this.state.lng === "FR"){

            alert("Erreur :" + errocode + ", vous n'avez pas assez de crédit pour transcrire le fichier.")
            
            
          }else{

            alert("Erreur :" + errocode + ", you do not have enough credit to transcribe the file.")
 
          }
        
          document.location.href = "/Buycredit";

          return

        }

        alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

        this.setState({
            soundlink: '',
            soundname : '',
            idaudioselect : 0,
            isModaltranscription: false,
            loadershow: false
        })

      });  
        
  };


  //CHARGEMENT  ET CALCUL SUR L'ANALYSE DES SENTIMENTS PAR DIARIZATION
  loadpseakerafterdiarize(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0;
        var Neutre = 0;
        var Negatif = 0;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          if(item.sentimentspeaker === 'positive'){
            Positif++
          }

          if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
            Neutre++
          }

          if(item.sentimentspeaker === 'negative'){
            Negatif++
          }


          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker == 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);

      this.setState({
        speakers: data,
        speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
        speakernegatif: SentimentGlobaleNegatif.toFixed(2),
        loadershow: false,
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des audios";
      }else{
        document.title = "Audio list";
      }

    var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

      this.refreshlistsound();   
       
  }
  

  //SELECTION DU RESULTAT DE LA DIARIZATION
  sentimentvalue(idaudiospeak,typeaction,typetranscription) {


    if(this.state.lng === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    this.setState({ 

      loadershow: true,
      soundlink: this.state.wbslink + "sound/" + idaudiospeak,
      
      

    });

    if(typeaction=="vue"){
      this.setState({
        idaudioselect : idaudiospeak,
      })
    }

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",idaudiospeak);

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

    fetch(this.state.wbslink + `listspeech`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;
  
      if (!res.ok) {
          
          return res.json().then(json => { throw json; });
  
      }
  
      return res.json();
  
    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.loadpseaker(data,typeaction,typetranscription)
  
      }else{
        this.setState({ speakers: []});
      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })
      
};   


//ALERT TRANSCRIPTION DEJA EFFECTUEE
diarizationeffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription déjà effectué.')
  }else{
    alert('Transcription finish.')
  }  

}


//ALERT TRANSCRIPTION NON EFFECTUEE
diarizationnoneffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription non effectué.')
  }else{
    alert('Transcription not finish.')
  }  

}


//CHARGEMENT DE LA DIARIZATION
  async loadpseaker(data,typeaction,typetranscription){

    

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;
        
        var idscroll =0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0.00;
        var Neutre = 0.00;
        var Negatif = 0.00;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

         
          if(parseInt(item.speaker) === 1){

            idscroll = parseInt(item.iddiarization);

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif: parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      //SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      ///SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      //SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);
      {/*speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
      speakernegatif: SentimentGlobaleNegatif.toFixed(2),*/}

      this.setState({
        speakers: data,
        loadershow: false,
      }) 

      if(typeaction=="vue"){
        this.setState({
        isModaldetails : true,
        })
      }else{

        await new Promise(resolve => setTimeout(resolve, 1500));

        this.setState({
          printbutton : true,
          })


        if(typetranscription==="compterendu"){
          this.Export2Word()
        }
        
        //document.getElementById(this.state.soundname).click();

      }

      if(this.state.lng === "FR"){
        document.title = "Liste des audios";
      }else{
        document.title = "Audio list";
      }
    
    {/*var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }*/}
       
  }

  
//SELECTION BAR CHART ET SCROLLER VERS LIGNE DU SPEECH DE L'ANALYSE DE SENTIMENT
  selectligne(idscroll){

    var idline = idscroll;

    var element = document.getElementById(idline);

    if(element !== 'null' && parseInt(idline)>0){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

    idline = 0;

    element = 'null';
    
  }

  //AFFICHER MODAL MISE JOUR AUDIO
  updatemodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound,comment,typetranscription) {
   
    this.setState({ isModalupdate: true,
      soundlink: this.state.wbslink + "sound/" + idaudio,
      soundname : namesound,
      idaudioselect : idaudio,
      //thematique: thematiqueupdate,
      comment: comment,
      speaker1: speaker1,
      speaker2: speaker2,
      typetranscription: typetranscription,
      speakers: []});
    //this.themeatiquesound(idaudio);      
    };   

  themeatiquemodal(idaudio,thematiqueupdate) {
    this.setState({  idaudioselect : idaudio, thematique: thematiqueupdate, speakers: []});
    //this.themeatiquesound(idaudio);      
};   


//METTRE A JOUR lES INFORMATIONS DE L'AUDIO
updatesound () {

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("idaudio",this.state.idaudioselect);
      formData.append("soundname",this.state.soundname);
      formData.append("comment",this.state.comment);
      formData.append("speaker1",this.state.speaker1);
      formData.append("speaker2",this.state.speaker2);

      let token = sessionStorage.getItem('tokenunsaid');

      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

      fetch(this.state.wbslink + `updatesound`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ token},
        body: formData

        })

      .then(res => {

        errocode = res.status;

        if (!res.ok) {
            
            return res.json().then(json => { throw json; });

        }

        return res.json();

      })
      .then(data => {

      //if(data.length > 0){
        
        if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

          for (var i = 0; i < this.state.listsound.length; i++) {
            if (this.state.listsound[i].linksound === this.state.idaudioselect) {
              this.state.listsound[i].nomaudio = this.state.soundname;
              this.state.listsound[i].comment = this.state.comment;
              this.state.listsound[i].speaker1 = this.state.speaker1;
              this.state.listsound[i].speaker2 = this.state.speaker2;
            }
          }

          for (var i = 0; i < this.state.listsound.length; i++) {
            if (this.state.listsound[i].linksound === this.state.idaudioselect) {
              this.state.listsound[i].nomaudio = this.state.soundname;
              this.state.listsound[i].comment = this.state.comment;
              this.state.listsound[i].speaker1 = this.state.speaker1;
              this.state.listsound[i].speaker2 = this.state.speaker2;
            }
          }

          for (var i = 0; i < this.state.listsound.length; i++) {
            if (this.state.listsound[i].linksound === this.state.idaudioselect) {
              this.state.listsound[i].nomaudio = this.state.soundname;
              this.state.listsound[i].comment = this.state.comment;
              this.state.listsound[i].speaker1 = this.state.speaker1;
              this.state.listsound[i].speaker2 = this.state.speaker2;
            }
          }

          this.setState({ soundname:'', comment :'', speaker1name:'',speaker2name:'', isModalupdate:false});

          if(this.state.lng === "FR"){

            alert("Modification bien éfféctuée.")
    
          }else{
    
            alert("Update successful.")
            
          }

        }else{

          this.setState({ comment :'', commentbutton : false });

        }

     
    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    })

    return;
    
};   


//AFFICHER MODAL SUPPRESSION AUDIO
deletemodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound) {
   
  this.setState({
    isModaldelete: true,
    soundname : namesound,
    idaudioselect : idaudio,
   });
  //this.themeatiquesound(idaudio);      
};   

//METTRE A JOUR LA THEMATHIQUE
  themeatiquesound () {

    fetch(this.state.wbslink + `thematique.php`,{                  

              method:'post',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique})

      })
      .then(response => response.json())
      .then(data => {

          this.setState({ isModalthematique: false, thematique :'', idaudioselect :0, thematiquebutton : false });

          this.refreshlistsound();

      })
      .catch(error => {
            this.setState({ listsound: [], listsoundinit:[],isModalthematique: false, thematique :'', idaudioselect :0, thematiquebutton : false });
      });   
      
};   


//METTRE A JOUR LA COMMENTAIRE
updatecomment () {

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("idaudio",this.state.idaudioselect);
      formData.append("comment",this.state.comment);
  
  
      let token = sessionStorage.getItem('tokenunsaid');
  
      const myDecodedToken = decodeToken(token);
  
      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);
  
      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
  
      token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")
  
      fetch(this.state.wbslink + `updatecomment`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ token},
        body: formData
  
        })
  
      .then(res => {
  
        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {
  
      if(data.length > 0){
        
        if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

          for (var i = 0; i < this.state.listsound.length; i++) {
            if (this.state.listsound[i].linksound === this.state.idaudioselect) {
              this.state.listsound[i].comment = this.state.comment;
            }
          }

          for (var i = 0; i < this.state.listsoundinit.length; i++) {
            if (this.state.listsoundinit[i].linksound === this.state.idaudioselect) {
              this.state.listsoundinit[i].comment = this.state.comment;
            }
          }

          for (var i = 0; i < this.state.listsoundpage.length; i++) {
            if (this.state.listsoundpage[i].linksound === this.state.idaudioselect) {
              this.state.listsoundpage[i].comment = this.state.comment;
            }
          }

          this.setState({ comment :'', commentbutton : false });

  
        }else{
          this.setState({ comment :'', commentbutton : false });
        }
  
      }else{
  
        this.setState({ speakers: []});
  
      }
  
    })
    .catch(err => {
  
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";
  
        return
  
      }
  
      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
      this.setState({
        loadershow: false
      })
  
    })
 
    
};   

//METTRE A JOUR LE NOM DE L'AUDIO
updatesoundname () {

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",this.state.idaudioselect);
    formData.append("soundname",this.state.soundname);


    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

    fetch(this.state.wbslink + `updatesoundname`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

        for (var i = 0; i < this.state.listsound.length; i++) {
          if (this.state.listsound[i].linksound === this.state.idaudioselect) {
            this.state.listsound[i].nomaudio = this.state.soundname;
          }
        }

        for (var i = 0; i < this.state.listsoundinit.length; i++) {
          if (this.state.listsoundinit[i].linksound === this.state.idaudioselect) {
            this.state.listsoundinit[i].nomaudio = this.state.soundname;
          }
        }

        for (var i = 0; i < this.state.listsoundpage.length; i++) {
          if (this.state.listsoundpage[i].linksound === this.state.idaudioselect) {
            this.state.listsoundpage[i].nomaudio = this.state.soundname;
          }
        }

        this.setState({ soundname :'', soundnamebutton : false });

      }else{

        this.setState({ soundname :'', soundnamebutton : false });

      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })

    
};   

//AFFICHER MODAL POUR MODIFIER LE NOM DES SPEAKER
speakermodal(idaudio,speaker1update,speaker2update) {
  this.setState({idaudioselect : idaudio, speaker1: speaker1update, speaker2: speaker2update});
  //this.themeatiquesound(idaudio);      
};   


//METTRE A JOUR LE NOM DES SPEAKER
speakermodalsound () {

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",this.state.idaudioselect);
    formData.append("speaker1",this.state.speaker1);
    formData.append("speaker2",this.state.speaker2);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

    fetch(this.state.wbslink + `updatespeaker`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

        for (var i = 0; i < this.state.listsound.length; i++) {
          if (this.state.listsound[i].linksound === this.state.idaudioselect) {
            this.state.listsound[i].speaker1 = this.state.speaker1;
            this.state.listsound[i].speaker2 = this.state.speaker2;
          }
        }

        for (var i = 0; i < this.state.listsoundinit.length; i++) {
          if (this.state.listsoundinit[i].linksound === this.state.idaudioselect) {
            this.state.listsoundinit[i].speaker1 = this.state.speaker1;
            this.state.listsoundinit[i].speaker2 = this.state.speaker2;
          }
        }

        for (var i = 0; i < this.state.listsoundpage.length; i++) {
          if (this.state.listsoundpage[i].linksound === this.state.idaudioselect) {
            this.state.listsoundpage[i].speaker1 = this.state.speaker1;
            this.state.listsoundpage[i].speaker2 = this.state.speaker2;
          }
        }

        this.setState({  speaker1: '', speaker2: '', speakerbutton : false });


      }else{

        this.setState({ speaker1: '', speaker2: '', speakerbutton : false });

      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })
    
};   


//SUPPRESSION DE L'AUDIO
deletesound() {

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",this.state.idaudioselect);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "")

    fetch(this.state.wbslink + `trashsound`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

        this.setState({ soundname :'', idaudioselect :0, isModaldelete : false });

        this.refreshlistsound();

      }else{
        this.setState({ speakers: []});
      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })
    
};   

//AFFICHER MODAL DETAILS AUDIO
ecouteraudio (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique,comment,summarization,dataextraction,idsociety,typetranscription) {

  this.setState({
    soundlink: this.state.wbslink + "sound/" + soundid ,
    soundname : soundnameshow,
    idaudioselect : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    comment:comment,
    summarization:summarization,
    dataextract:dataextraction,
    idsoc:idsociety,
    speaker1sentiment: [],
    speaker2sentiment: [],
    printbutton:false,
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
    commentbutton:false,
    speakerbutton:false,
    thematiquebutton:false,
    printbutton:false,
    typetranscription:typetranscription,
  });

  this.sentimentvalue(soundid,"vue")

};   

//AFFICHER MODAL DETAILS AUDIO
infoaudio (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique,comment,summarization,dataextraction,idsociety,typetranscription) {

  this.setState({
    soundlink: this.state.wbslink + "sound/" + soundid ,
    soundname : soundnameshow,
    idaudioselect : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    comment:comment,
    summarization:summarization,
    dataextract:dataextraction,
    idsoc:idsociety,
    speaker1sentiment: [],
    speaker2sentiment: [],
    printbutton:false,
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
    commentbutton:false,
    speakerbutton:false,
    thematiquebutton:false,
    printbutton:false,
    typetranscription:typetranscription,
  });

  this.sentimentvalue(soundid,"vue")

};   

//SCROLLER VERS SPEECH SUR LE DETAILS AUDIO
scrolltospeech=(payload)=>{

  
  var idline = JSON.stringify(payload.payload.idspeak).replace(/\"/g, "")

  var element = document.getElementById(idline);

  if(element !== 'null' && parseInt(idline)>0){
    element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
  }

  idline = 0;

  element = 'null';

  /*text.slice(0, text.lastIndexOf(':'))*/
  
  
     
};

//SELECTIONNER NOMBRE DE SPEAKER
selectnumber(e) {
  this.setState({ speakernumber: e.target.value });
}


//SELECTIONNER LANGUE DE L'AUDIO
selectlng(e) {
  this.setState({ soundlng: e.target.value });
}

//IMPRIMER LE DETAILS DE L'AUDIO
texttoprint (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique,summarization,dataextraction,idsociety,typetranscription){

  this.setState({
    soundlink: this.state.wbslink + "sound/" + soundid ,
    soundname : soundnameshow,
    idaudioselect : 0,
    idaudioselectprint : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    summarization:summarization,
    dataextract:dataextraction,
    idsoc:idsociety,
    speaker1sentiment: [],
    speaker2sentiment: [],
    printbutton:false,
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
    typetranscription:typetranscription,
  });

  this.sentimentvalue(soundid,"print",typetranscription)

}

//REINITIALISER LES VARIABLES DU DETAILS AUDIOS
cleardata(){

  this.setState({
    soundlink: "",
    soundname : "",
    idaudioselect : 0,
    idaudioselectprint:0,
    speaker1: [],
    speaker2: [],
    speaker1name: "",
    speaker2name: "",
    thematique : "",
    dataextract:"",
    speaker1sentiment: [],
    speaker2sentiment: [],
    speakerpositif: 0,
    speakerneutre: 0,
    speakernegatif: 0,
    speaker:[],
    printbutton:false,
    typetranscription:'',
  });


}

//DETECTION BOUTON APPUYER 
handleKeypress = event => {

  if (event.key === 'Enter' || event.code === "NumpadEnter") {
    
    if(this.state.idaudioselect > 0)

      if(this.state.thematiquebutton == true){
        
        this.themeatiquesound();

        return;

      }

      if(this.state.speakerbutton == true){
        
        this.speakermodalsound();

        return;

      }

      if(this.state.commentbutton == true){
        
        this.updatecomment();

        return;

      }

      if(this.state.soundnamebutton == true){
        
        this.updatesoundname();

        return;

      }

    }

  if (event.key === 'Escape') {
    
    if(this.state.idaudioselect > 0)

    this.refreshlistsound();

    return;

  }

}


//CHARGEMENT DE LA LISTES AUDIOS
async reloaddata(datemargin){

  let idsc = parseInt(sessionStorage.getItem('idsociety'));
  let idtype = parseInt(sessionStorage.getItem('typeuser'));
  let iduserselect = parseInt(sessionStorage.getItem('iduser'));

  const response = await fetch(this.state.wbslink + 'dashboard.php',{                  
    
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      typeuser:idtype,
      idsociety:idsc,
      idusers:iduserselect,
      datemargin:datemargin,
    })});
  const data = await response.json();

  this.setState({
    nbreuser:parseInt(JSON.stringify(data.nbreuser).replace(/\"/g, "")),
    nbrecenter:parseInt(JSON.stringify(data.nbrecenter).replace(/\"/g, "")),
    nbreaudio:parseInt(JSON.stringify(data.audio).replace(/\"/g, "")),
    audionotdiarized:parseInt(JSON.stringify(data.audionotdiarized).replace(/\"/g, "")),
    credit:parseInt(JSON.stringify(data.credit).replace(/\"/g, "")),
  });


}


//FILTRER LA LISTE DES AUDIOS
filterlist(e){

  this.setState({filterdata: e, idaudioselect:0})

  if(e.lenght == 0){
    this.setState({listsound: this.state.listsoundinit})
    return;
  }

  const filteredItems = this.state.listsoundinit.filter((soundlist) => {
    return (soundlist.nomaudio).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker1).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker2).toLowerCase().includes(e.toLowerCase())  || (soundlist.summarization).toLowerCase().includes(e.toLowerCase()) || (soundlist.texttranscript).toLowerCase().includes(e.toLowerCase());
  });

  
  if(filteredItems.length>0){

    this.setState({listsound: filteredItems})

    var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(filteredItems.length / this.state.perPage),
              listsound:slice,
              listsoundpage:filteredItems,
          })

  }else{

    this.setState({listsound: [], listsoundpage:[]})

  } 
  
}

//RAFRAICHIR LA LISTE
refreshfilter(filteredData){

  this.setState({listsound: filteredData})  

}


//SELECTIONNER TYPE DE TRANSCRIPTION
selecttypetranscription(e) {
  this.setState({ typetranscription: e.target.value });
  if(e.target.value == "sentiment"){
    this.setState({ speakernumber: 2 });
  }
}


//EXPORT EN WORD DU DETAILS AUDIO
Export2Word() {
  var preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>" + this.state.soundname + "</title></head><body>";
  var postHtml = "</body></html>";

  var html = preHtml + document.getElementById("datatoprint").innerHTML + postHtml;

  var blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });

  // Specify link url
  var url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  var filename = this.state.soundname + ".doc";

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  //triggering the function
  downloadLink.click();

  document.body.removeChild(downloadLink);

  this.setState({
    printbutton : false,
    })

  this.cleardata()
}

  render() {

    const handleChange = (e) => {
      this.setState({selectedValue: e.target.value})
    }

          return (

            <div>

            {this.state.loadingdata == true?

              null
            
              :

              <div className="App" style={{marginTop:'60px'}}>
                  
              {this.state.tokenvalue === '' ?
                        

                        <Redirect push to="/Login"/>
                          

                        :

                        null

                }

                  <BrowserView>

                  {/*
                  <div className=' d-flex flex-row justify-content-center' >
                    <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('all')}>{this.state.lng ==="FR" ? 'Tous les temps' : 'All time'}</Button>
                    <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('year')}>{this.state.lng ==="FR" ? 'Dernière année' : 'Last year'}</Button>
                    <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('month')}>{this.state.lng ==="FR" ? 'Dernier mois' : 'Last month'}</Button>
                    <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('week')}>{this.state.lng ==="FR" ? 'Dernière semaine' : 'Last week'}</Button>
                  </div>
                  */}

                    <div className="row" style={{marginBottom:"40px", marginTop:"20px"}}>

                      <div className="col-2 dateinput" >
                        <p>{this.state.lng ==='FR'? 'Début filtre': 'Start filter'}</p>
                          <DatePicker
                                  maxDate={new Date()}
                                  selected = {dateselectdu}
                                  onChange={(date) => this.selectdatedu(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  locale="fr"
                                  dateFormat="dd/MM/yyyy"
                                  dropdownMode="select"
                                  withPortal
                                />

                        
                      </div>

                      <div className="col-2 dateinput">

                        <p>{this.state.lng ==='FR'? 'Fin filtre': 'End filter'}</p>

                            <DatePicker
                                    maxDate={new Date()}
                                    selected = {dateselectau}
                                    onChange={(date) => this.selectdateau(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    dropdownMode="select"
                                    withPortal
                                  />
                      </div>

                      <div className="col-2 dateinput">
                        <p style={{marginTop:"-5px"}}>{this.state.lng ==='FR'? 'Action': 'Action'}</p>
                              <Button type="submit" className="btn" style={{width:'100px'}} variant="primary" onClick={() => this.selectlistdate()}>{this.state.lng ==='FR'? 'Filtrer': 'Filter'}</Button>
                      </div>

                      <div className="col-2 dateinput">
                        <p style={{marginTop:"-5px"}}></p>
                              <Button type="submit" className="btn" style={{width:'100px',marginTop:"20px"}} variant="primary" onClick={() => this.selectlistaudio()}>{this.state.lng ==='FR'? 'Tous': 'All'}</Button>
                      </div>

                      <div className="col-4 d-flex flex-row-reverse ">
                        <Button  style={{ marginTop:"20px",marginBottom:'-20px',width:'200px',height:"60px", marginRight:"100px"}} onClick={() => this.setState({isModaladd:true})} variant="success"><span><AddIcon /></span>{this.state.lng ==="FR" ? 'Ajouter Fichier' : 'Add file'}</Button>
                      </div>

                      </div>
                  
                  </BrowserView>


                  <MobileView>

                  <div className='paginationmobile' style={{ backgroundColor:this.state.listsound.length>0?"#162938":"transparent", bottom:20}} >

                    {this.state.listsound.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    <Button   className="btn" style={{width:'50px',height:"50px", borderRadius:"25px",zIndex:10000}} onClick={() => this.setState({isModaladd:true})} variant="success"><span><AddIcon /></span></Button>

                  </div>

                    <div className='d-flex align-items-center flex-row' style={{margin:"10px"}}>
 
                      <p style={{margin:"10px"}}>{this.state.lng ==='FR'? 'Début ': 'Start '}</p>
                      <DatePicker
                              maxDate={new Date()}
                              selected = {dateselectdu}
                              onChange={(date) => this.selectdatedu(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              dropdownMode="select"
                              withPortal
                            />

                      <p style={{margin:"10px"}}>{this.state.lng ==='FR'? 'Fin ': 'End '}</p>
                      <DatePicker
                              maxDate={new Date()}
                              selected = {dateselectau}
                              onChange={(date) => this.selectdateau(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              dropdownMode="select"
                              withPortal
                            />
                     

                      <Button className="btn" style={{width:'50px',height:"50px", borderRadius:"25px", marginLeft:"15px"}} variant="primary" onClick={() => this.selectlistdate()}><span><SearchIcon /></span></Button>

                      </div>

                      <Button className="btn" style={{width:'100px'}} variant="primary" onClick={() => this.selectlistaudio()}>{this.state.lng ==='FR'? 'Tous': 'All'}</Button>

                  </MobileView>
                 
                  <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>
                                   
  
                {/*{this.state.loadershow === true ?
                      

                <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                  

                :

                  <div>

                    {this.state.listsound.length === 0 ?

                    <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio eregistré.' : 'No sound saved.'}&nbsp;</p>

                    :

                    null

                    }
                    
                  </div>

                }*/}

                {this.state.listsound.length === 0 ?

                <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio enregistré.' : 'No sound saved.'}&nbsp;</p>

                :

                null

                }


          <div className='centrervue'>

            <BrowserView>

                {this.state.listsound.length>0?
        
                <Table>
                  <Thead>
                    <Tr valign="top">
                      
                      <Th >{this.state.lng ==="FR" ? 'Transcrire' : 'Transcribe Call'}</Th>
                      <Th >{this.state.lng ==="FR" ? 'ID de l\'appel' : 'ID Call'}</Th>
                      <Th style={{width:"80px"}}>{this.state.lng ==="FR" ? 'Durée' : 'Duration'}</Th>
                      <Th style={{width:"220px"}}>{this.state.lng ==="FR" ? 'Date de transcription' : 'Transcription date'}</Th>
                      {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Thématique' : 'Update Thematic'}</TableCell>*/}
                      {/*<Th >{this.state.lng ==="FR" ? 'Thématique' : 'Thematic'}</Th>*/}
                      {/*<Th >{this.state.lng ==="FR" ? 'Résumé' : 'Summarization'}</Th>*/}
                      <Th >{this.state.lng ==="FR" ? 'Statut' : 'Status'}</Th>
                      {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Speaker' : 'Update Speaker'}</TableCell>*/}
                      <Th >Speaker</Th>
                      <Th >{this.state.lng ==="FR" ? 'Commentaire' : 'Comment'}</Th>
                      <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Détails' : 'Call Details'}</Th>
                      <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Th>
                      <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Exporter le document' : 'Export document'}</Th>
                      <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Télécharger l\'audio' : 'Download audio'}</Th>
                      <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Th>
                     
                    </Tr>
                  </Thead>
                  <Tbody>
                  {this.state.listsound.filter(sound => sound.idaudio > 15 ).map((row) => (
                      <Tr key={row.linksound} style={{backgroundColor: row.linksound === this.state.idaudioselect ? "#333333": "white"}} className="tableborder" valign="top" >
                        
                        <Td >{parseInt(row.status) == 0 ?

                              <button  className="btn-primary btn-circle btn-xl" style={{margin:"5px", width:"40px", height:"40px"}} onClick={() => this.audiosend(row.linksound,row.nomaudio,row.duree,row.langue,row.typetranscription)}><span><ReadMoreIcon /></span></button>

                              :

                              <div>

                                  {parseInt(row.status) == 1 ?

                                    <div className="loadertranscription" style={{margin:"5px",marginLeft:"38%", textAlign:"center"}} onClick={() => alert(this.state.lng ==="FR" ? 'Dans la file' : 'In progress')}></div>
                                    
                                    :

                                    <button  className="btn-gris btn-circle btn-xl" style={{margin:"5px", width:"40px", height:"40px"}} onClick={() => this.diarizationeffectue()}><span><CheckBoxIcon /></span></button>

                                  }
                              </div>

                              }

                        </Td>
                        
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",paddingTop:"0px"}} >
                        
                        {row.linksound === this.state.idaudioselect?
                          
                          <div className="container " >

                            {this.state.soundnamebutton== true?

                              <div className="row ">


                                <div className="col " style={{width:"25px",paddingTop:"20px"}}>
                                  
                                  {this.state.soundnamebutton== true?
                                    <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                    :
                                    null
                                  }
                                  
                                </div>
                                <div className="col">

                                  {this.state.thematique === "" ?

                                    <div>

                                        {this.state.soundnamebutton== true?

                                            <textarea type="textarea" name="thematique" rows={3}  value={this.state.soundname} onChange={(e) => this.setState({ soundname: e.target.value})}/>

                                            :

                                            <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.linksound,soundnamebutton:false})}>{row.nomaudio}</p>
                                      
                                        }


                                    </div>


                                      :

                                      <div>

                                          {this.state.soundnamebutton== true?

                                          <textarea type="textarea" name="thematique" rows={3}  value={this.state.soundname} onChange={(e) => this.setState({ soundname: e.target.value})}/>

                                          :

                                          <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.linksound,soundnamebutton:false})}>{row.nomaudio}</p>

                                          }

                                      </div>


                                  }
                                  
                                </div>
                                <div className="col" style={{width:"25px",paddingTop:"20px"}}>
                                  {this.state.soundnamebutton== true?
                                    <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                    :
                                    null
                                  }        
                                </div>
                              </div>

                              :



                              <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.linksound,soundnamebutton:false})}>{row.nomaudio}</p>

                            
                            
                            }

                          </div>

                          :

                          <p style={{paddingTop:"10px"}} onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.linksound,soundnamebutton:false})}>{row.nomaudio}</p>
                          
                          }

                          </Td>
                          
                        {/*<Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">{row.nomaudio.slice(row.nomaudio.lenght, -4)}</Td>*/}
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",paddingTop:"10px"}}>{parseInt(row.duree)>3599? Math.floor(row.duree / 3600)+"h": ""} {Math.floor((row.duree % 3600) / 60)}m {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s</Td>
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",paddingTop:"10px"}}>{parseInt(row.status) < 2 ? "" : <p>{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}s</p>}</Td>
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",textAlign:"center",paddingTop:"10px"}} className="maxwidthline">{parseInt(row.status) == 0? <p>{this.state.lng ==="FR" ? 'Non transcrit ' : 'No transcript'}</p>:null}{parseInt(row.status) == 1 ? <p>{this.state.lng ==="FR" ? 'Dans la file' : 'In progress'}</p>:null}{parseInt(row.status) == 2 ? <p>{this.state.lng ==="FR" ? 'Terminé' : 'Finished'}</p>:null}</Td>
                        {/*<Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",textAlign:"left"}} className="maxwidthline">{row.summarization.substring(0,100)} {row.summarization.length>100?" ...":""}</Td>*/}
                        {/*<TableCell ><button type="submit" className="btn-success btn-circle btn-xl" onClick={() => this.themeatiquemodal(row.linksound,row.thematique)}><span><AssignmentIcon  style={{color:"white"}}/></span></button>
                                </TableCell>*/}
                        
                        {/*
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">
                        
                        {row.linksound === this.state.idaudioselect?
                          
                          <div className="container " >

                            {this.state.thematiquebutton== true?

                              <div className="row ">


                                <div className="col " style={{width:"25px"}}>
                                  
                                  {this.state.thematiquebutton== true?
                                    <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                    :
                                    null
                                  }
                                  
                                </div>
                                <div className="col">

                                  {this.state.thematique === "" ?

                                    <div>

                                        {this.state.thematiquebutton== true?

                                            <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                            :

                                            <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                                      
                                        }


                                    </div>


                                      :

                                      <div>

                                          {this.state.thematiquebutton== true?

                                          <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                          :

                                          <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                                          }

                                      </div>


                                  }
                                  
                                </div>
                                <div className="col" style={{width:"25px"}}>
                                  {this.state.thematiquebutton== true?
                                    <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                    :
                                    null
                                  }        
                                </div>
                              </div>

                              :



                              <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                            
                            
                            }

                          </div>

                          :

                          <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                          
                          }

                          </Td>
                          */}
                        {/*<TableCell ><button type="submit" className="btn-warning btn-circle btn-xl" style={{color:'white'}}onClick={() => this.speakermodal(row.linksound,row.speaker1,row.speaker2)}><span><RecordVoiceOverIcon /></span></button></TableCell>*/}
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">
                        
                      
                        
                        {row.linksound === this.state.idaudioselect?
                          
                          <div className="container noselect" >

                            {this.state.speakerbutton== true?

                              <div className="row ">


                                <div className="col " style={{width:"10px", marginTop:"20px"}}>
                                  
                                  {this.state.speakerbutton== true?
                                    <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                    :
                                    null
                                  }
                                  
                                </div>
                                <div className="col">

                                  {this.state.speaker1 === "" ?

                                    <div>

                                        {this.state.speakerbutton== true?

                                            <div style={{width:"200px"}}>
             
                                                <input  className="noselect" type="text" placeholder={this.state.speaker1} ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                               
                                                <input  className="noselect" type="text" placeholder={this.state.speaker2} ref="speaker2" style={{marginTop:"10px"}} value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>

                                            </div>

                                            :

                                            <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.linksound,speakerbutton:false})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>
                                      
                                        }


                                    </div>


                                      :

                                      <div>

                                          {this.state.speakerbutton== true?

                                            <div style={{width:"200px"}}>

                                              
                                              <input  className="noselect" type="text" placeholder={this.state.speaker1} ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                              
                                              <input  className="noselect" type="text" placeholder={this.state.speaker2} ref="speaker2" style={{marginTop:"10px"}}value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>

                                            </div>

                                          :

                                            <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.linksound,speakerbutton:false})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>

                                          }

                                      </div>


                                  }
                                  
                                </div>
                                <div className="col" style={{width:"10px", marginTop:"20px"}}>
                                  {this.state.speakerbutton== true?
                                    <span style={{color:'#00934c'}} onClick={() => this.speakermodalsound()}><SaveAsIcon /></span>
                                    :
                                    null
                                  }        
                                </div>
                              </div>

                              :



                              <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.linksound,speakerbutton:false})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>

                            
                            
                            }

                          </div>

                          :

                          <p style={{paddingTop:"10px"}} onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.linksound,speakerbutton:false})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>
                          
                          }
                        
                        
                        </Td>
                        <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline" >
                        
                        {row.linksound === this.state.idaudioselect?
                          
                          <div className="container " >

                            {this.state.commentbutton== true?

                              <div className="row ">


                                <div className="col " style={{width:"25px"}}>
                                  
                                  {this.state.commentbutton== true?
                                    <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                    :
                                    null
                                  }
                                  
                                </div>
                                <div className="col">

                                  {this.state.comment === "" ?

                                    <div>

                                        {this.state.commentbutton== true?

                                            <input type="text" className="inputtable" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value})}></input>

                                            :

                                            <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.linksound,commentbutton:false})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>
                                      
                                        }


                                    </div>


                                      :

                                      <div>

                                          {this.state.commentbutton== true?

                                          <input type="text" className="inputtable" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value})}></input>

                                          :

                                          <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.linksound,commentbutton:false})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>

                                          }

                                      </div>


                                  }
                                  
                                </div>
                                <div className="col" style={{width:"25px"}}>
                                  {this.state.commentbutton== true?
                                    <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                    :
                                    null
                                  }        
                                </div>
                              </div>

                              :



                              <p style={{paddingTop:"10px"}} onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.linksound,commentbutton:false})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour commentaire' : 'Click to add comment'} </p>}</p>

                            
                            
                            }

                          </div>

                          :

                          <p style={{paddingTop:"10px"}} onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.linksound,commentbutton:false})}>{row.comment}</p>
                          
                          }
                          
                          
                        </Td>
                        <Td style={{textAlign:'center'}}>

                          {row.typetranscription === "sentiment"?

                            <div>
                                {parseInt(row.status) < 2 ?

                                    null

                                    :

                                    <div>
                                      {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?

                                      <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                                      :

                                      null

                                      }


                                      {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                                      <button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                                      :

                                      null

                                      }

                                      {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && parseInt(row.iddiarizeur) > 0 ?

                                      <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                                      :

                                      null

                                      }

                                  </div>

                              }

                            </div>

                            :

                            <div>

                                {parseInt(row.status) < 2 ?

                                  null
                                
                                :

                                <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><span style={{color:'#2A83E8'}}><VisibilityIcon /></span></button>

                                }

                            </div>
                          
                        
                          }

                          

                          {/*{parseInt(row.sentimentglobale) === 1 && parseInt(row.iddiarizeur) > 0?

                          <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                          :

                          null

                          }


                          {parseInt(row.sentimentglobale) === 2 && parseInt(row.iddiarizeur) > 0?

                          <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                          :

                          null

                          }

                          {parseInt(row.sentimentglobale) === 0 && parseInt(row.iddiarizeur) > 0 ?

                          <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                          :

                          null

                          }*/}

                          {parseInt(row.status) < 2 ?

                          <button  className="btn-gris btn-circle btn-xl" onClick={() => this.diarizationnoneffectue()}><span style={{color:"#333333"}}><VisibilityOffIcon /></span></button>

                          :

                          null

                          }

                        </Td>

                        <Td >

                          {parseInt(row.status) == 2 ?

                            <button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.updatemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio,row.comment,row.typetranscription)}><span><EditIcon style={{color:"#2A83E8"}}/></span></button>

                          :

                            null

                          }
     
                        </Td>

                                <Td> 

                                {parseInt(row.status) < 2 ?

                                    null

                                    :

                                    <div>

                                      
                                      {row.linksound === this.state.idaudioselectprint ? 
                                      
                                        <div>



                                            {this.state.printbutton== true ?
                                                  
                                                  <div>

                                                      {row.typetranscription === "sentiment"?
                                                          <ReactToPrint
                                                        
                                                          trigger={() => (
                                                            <button id={row.nomaudio} className="btn btntransparent btn-circle btn-xl"><span><LocalPrintshopIcon style={{color:"#929292"}}/></span></button>
                                                            
                                                          )}
        
                                                          onAfterPrint={() => (
                                                            this.cleardata() 
                                                          )}
        
                                                          documentTitle = {row.nomaudio}
                                                          
                                                          content={() => this.componentRef}
        
                                                          />

                                                        :

                                                          <div>

                                                            <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>

                                                          </div>

                                                        }

                                                  </div>
                                                  
                                              :

                                              <div>

                                                {this.state.speaker1.length>0?

                                                    <Loader type="spinner-cub" bgColor={'red'} color={'white'} size={25} />

                                                    

                                                  :

                                                  null

                                                }

                                              </div>


                                            }

                                        </div>
                                        
                                        
                                        : 

                                        <button className="btn btntransparent btn-circle btn-xl" onClick={() => {
                                          this.texttoprint(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.summarization,row.dataextraction,row.idsociety,row.typetranscription);
                                        }} >

                                          {row.typetranscription === "sentiment"?
                                             <span><PictureAsPdfIcon style={{color:"#929292"}}/></span>
                                             :
                                             <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>
                                          }
                                         
                                        </button>


                                      }

                                      

                                    </div>

                                }

                                </Td>
                                <Td ><a href={this.state.wbslink+"sound/"+row.linksound} download ><span><DownloadIcon style={{color:"#929292", marginTop:"16px"}}/></span></a></Td>
                                <Td ><button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button> </Td>
                                

                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                :

                  null

                }
                
                {this.state.listsound.length>0?

                  <ReactPaginate
                  previousLabel={"<< prev"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={"next >>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>
              
                  :

                  null
                }
                
            </BrowserView>
            
            <MobileView>
              
              {/* 
              <div className='paginationmobile' >

                {this.state.listsound.length>0?

                  <ReactPaginate
                  previousLabel={"<<"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={">>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  
                }

              </div>
              */}


                {this.state.listsound.map((row) => (

                  <Card style={{ width: '90%', margin:"5%",fontSize:"14px",textAlign:"left", marginTop:"-10px", borderWidth:"2px", borderColor:"#ebebeb"}}>

                    <Card.Body  key={row.linksound}>

                    {parseInt(this.state.idtypeuser) < 4 ?

                      <div className="d-flex flex-row">

                      

                      <div style={{width:"95%"}}><p style={{color:"black", margin:"-10px"}}>

                        <span style={{fontWeight:"bold"}}>{row.prenomsutilisateur} {row.nomutilisateur}</span><br/>
                        
                        {parseInt(row.iddiarizeur)> 0 ?

                          <span style={{fontWeight:"bold"}}>{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}s<br/></span>
                          :

                          <span style={{fontWeight:"bold", color:"#dc3545"}}> {this.state.lng ==="FR" ? 'Non transcrit ' : 'No transcript'}<br/></span>

                        }

                        <span style={{fontWeight:"bold"}}>{row.nomaudio}</span><br/>
                        <span style={{fontWeight:"bold"}}>{parseInt(row.duree)>3599? Math.floor(row.duree / 3600)+"h": ""} {Math.floor((row.duree % 3600) / 60)}m {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s </span>
                        
                        {row.comment.lenght>0?
                        
                          <span ><br/>{row.comment}</span>

                          :
                          
                          null

                        }
                        
                        
                        </p>
                      </div>

                        <div>

                          {parseInt(row.iddiarizeur)> 0 ?

                            <div className="d-flex flex-column">

                              {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) && row.typetranscription === "sentiment" ?

                                <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#00934c'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                  
                                :

                                null

                              }

                              {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) && row.typetranscription === "sentiment" ?

                                <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#e40001'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                  
                                :

                                null

                              }

                              {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && parseInt(row.iddiarizeur) > 0 && row.typetranscription === "sentiment" ?

                                <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#e89900'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>

                                :

                                null

                              }


                              {row.typetranscription === "compterendu"?

                                <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#2A83E8'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>

                                :

                                null


                              }
                                        
                              <div style={{width:"5%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.updatemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio,row.comment,row.typetranscription)}><EditIcon sx={{ width: '40px', height:'40px' }} style={{color:"#2A83E8"}}/></span></div>
                              <div style={{width:"5%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><DeleteIcon sx={{ width: '40px', height:'40px' }} style={{color:"#dc3545"}}/></span></div>
                                      
                              {parseInt(row.status) < 2 ?

                                  null

                                  :

                                  <div>

                                    
                                    {row.linksound === this.state.idaudioselectprint ? 
                                    
                                      <div>

                                          {this.state.printbutton== true ?
                                                
                                                <div>

                                                    {row.typetranscription === "sentiment"?
                                                        <ReactToPrint
                                                      
                                                        trigger={() => (
                                                          <div id={row.nomaudio} ><span><LocalPrintshopIcon style={{color:"#929292", marginTop:"14px"}}/></span></div>
                                                          
                                                        )}

                                                        onAfterPrint={() => (
                                                          this.cleardata() 
                                                        )}

                                                        documentTitle = {row.nomaudio}
                                                        
                                                        content={() => this.componentRef}

                                                        />

                                                      :

                                                        <div>

                                                          <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>

                                                        </div>

                                                      }

                                                </div>
                                                
                                            :

                                            <div>

                                            {this.state.speaker1.length>0?

                                            <div className='loaderloading'></div>
                                                                                            

                                            :

                                            null

                                            }

                                            </div>


                                          }

                                      </div>
                                      
                                      
                                      : 

                                      <div onClick={() => {
                                        this.texttoprint(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.summarization,row.dataextraction,row.idsociety,row.typetranscription);
                                      }} >

                                        {row.typetranscription === "sentiment"?

                                          <span><PictureAsPdfIcon style={{color:"#929292", marginTop:"14px"}}/></span>

                                          :

                                          <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px", marginTop:"14px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>
                                        
                                        }
                                      
                                      </div>

                                    }

                                  </div>

                                  }

                            </div>

                            :

                            <div>

                              {parseInt(row.status) == 0 ?

                                <div className="d-flex flex-column">
                                  <div style={{width:"5%",  marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.audiosend(row.linksound,row.nomaudio,row.duree,row.langue,row.typetranscription)}><ReadMoreIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                  <div style={{width:"2%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><DeleteIcon sx={{ width: '40px', height:'40px' }} style={{color:"#dc3545"}}/></span></div>
                                </div>

                                :

                                <div>

                                    {parseInt(row.status) == 1 ?

                                      <div className="loadertranscription" style={{margin:"2%", textAlign:"center"}} onClick={() => alert(this.state.lng ==="FR" ? 'Dans la file' : 'In progress')}></div>

                                      :

                                      null

                                    }

                                </div>

                              }

                            </div>

                          }

                        </div>                         

                      </div>

                      :

                      <div className="d-flex flex-row">

                        <div style={{width:"95%"}}><p style={{color:"black", margin:"-10px"}}>
                          
                          {parseInt(row.iddiarizeur)> 0 ?

                            <span style={{fontWeight:"bold"}}>{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}s<br/></span>
                            :

                            <span style={{fontWeight:"bold", color:"#dc3545"}}> {this.state.lng ==="FR" ? 'Non transcrit ' : 'No transcript'}<br/></span>

                          }

                          <span style={{fontWeight:"bold"}}>{row.nomaudio}</span><br/>
                          <span style={{fontWeight:"bold"}}>{parseInt(row.duree)>3599? Math.floor(row.duree / 3600)+"h": ""} {Math.floor((row.duree % 3600) / 60)}m {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s </span>
                          
                          {row.comment.lenght>0?
                        
                            <span ><br/>{row.comment}</span>

                            :
                            
                            null

                          }
                          
                          </p>
                        </div>

                          <div>

                            {parseInt(row.iddiarizeur)> 0 ?

                              <div className="d-flex flex-column">

                                {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) && row.typetranscription === "sentiment" ?

                                  <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#00934c'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                    
                                  :

                                  null

                                }

                                {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) && row.typetranscription === "sentiment" ?

                                  <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#e40001'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                    
                                  :

                                  null

                                }

                                {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && parseInt(row.iddiarizeur) > 0 && row.typetranscription === "sentiment" ?

                                  <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#e89900'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>

                                  :

                                  null

                                }


                                {row.typetranscription === "compterendu"?

                                  <div style={{width:"5%", marginTop:"-10px", textAlign:"center"}}><span  style={{color:'#2A83E8'}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety,row.typetranscription)}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span></div>

                                  :

                                  null


                                }
                                          
                                <div style={{width:"5%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.updatemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio,row.comment,row.typetranscription)}><EditIcon sx={{ width: '40px', height:'40px' }} style={{color:"#2A83E8"}}/></span></div>
                                <div style={{width:"5%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><DeleteIcon sx={{ width: '40px', height:'40px' }} style={{color:"#dc3545"}}/></span></div>
                                        
                                {parseInt(row.status) < 2 ?

                                  null

                                  :

                                  <div>

                                    
                                    {row.linksound === this.state.idaudioselectprint ? 
                                    
                                      <div>

                                          {this.state.printbutton== true ?
                                                
                                                <div>

                                                    {row.typetranscription === "sentiment"?
                                                        <ReactToPrint
                                                      
                                                        trigger={() => (
                                                          <div id={row.nomaudio} ><span><LocalPrintshopIcon style={{color:"#929292", marginTop:"14px"}}/></span></div>
                                                          
                                                        )}

                                                        onAfterPrint={() => (
                                                          this.cleardata() 
                                                        )}

                                                        documentTitle = {row.nomaudio}
                                                        
                                                        content={() => this.componentRef}

                                                        />

                                                      :

                                                        <div>

                                                          <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px", marginTop:"14px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>

                                                        </div>

                                                      }

                                                </div>
                                                
                                            :

                                            <div>

                                              {this.state.speaker1.length>0?

                                                  <div className='loaderloading'></div>
                                                                                                   

                                                :

                                                null

                                              }

                                            </div>


                                          }

                                      </div>
                                      
                                      
                                      : 

                                      <div onClick={() => {
                                        this.texttoprint(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.summarization,row.dataextraction,row.idsociety,row.typetranscription);
                                      }} >

                                        {row.typetranscription === "sentiment"?

                                          <span><PictureAsPdfIcon style={{color:"#929292", marginTop:"14px"}}/></span>

                                          :

                                          <img src={process.env.PUBLIC_URL +'images/word2.png'} style={{width:"20px", height:"20px", marginTop:"14px"}} onClick={() => this.Export2Word()} alt="Unsaid"/>
                                        
                                        }
                                      
                                      </div>

                                    }

                                  </div>

                                  }

                              </div>

                              :

                              <div>

                                {parseInt(row.status) == 0 ?

                                  <div className="d-flex flex-column">
                                    <div style={{width:"5%",  marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.audiosend(row.linksound,row.nomaudio,row.duree,row.langue,row.typetranscription)}><ReadMoreIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                                    <div style={{width:"2%", marginTop:"10px", textAlign:"center"}}><span  style={{color:'#949494'}}  onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><DeleteIcon sx={{ width: '40px', height:'40px' }} style={{color:"#dc3545"}}/></span></div>
                                  </div>

                                  :

                                  <div>

                                      {parseInt(row.status) == 1 ?

                                        <div className="loadertranscription" style={{margin:"2%", textAlign:"center"}} onClick={() => alert(this.state.lng ==="FR" ? 'Dans la file' : 'In progress')}></div>

                                        :

                                        null

                                      }

                                  </div>

                                }

                              </div>

                            }

                          </div>                         
                      
                      </div>

                    } 

                    </Card.Body>

                  </Card>

                ))}

                <div style={{height:"50px", marginBottom:"50px"}}>

                </div>

            </MobileView>

                  
              
              </div> 

              {this.state.isModaldetails === true ?

                <div > 

                <Modal
                fullscreen
                show={this.state.isModaldetails}
                onHide={ () => this.setState({isModaldetails:false, speaker1:[], typetranscription:""})}
                centered
                animation={false}
                className="modaldetails"
                >
                 
                  <Modal.Body>

                  {this.state.typetranscription === "sentiment"?

                      <div>

                        <BrowserView>

                        <div className='modaldetailsdata' >

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>*/}

                            <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000'}}>
                              <button  className="btn btntransparent" onClick={() => this.setState({isModaldetails:false,speaker1:[], typetranscription:""})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                            </div>
                                                    
                                <div className="row">

                                  <div className="col-sm thematiquevalue">
                                    <div className="row">
                                        <div style={{width:'18%'}}>
                                        <img src={logo} style={{width:'100px', height:'70px', marginTop:'20px'}}/>
                                        {/*<span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>*/}
                                        </div>

                                        <div style={{width:'82%'}} className="d-flex flex-column align-items-center ">
                                          <p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>
                                          {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
                                          {/*<hr className="lineseparator"></hr>*/}
                                          <p style={{color:'white',textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>
                                        </div>

                                    </div>
                                    
                                  </div>
                                  
                                  {/*<div className="col-sm">
                                    
                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>

                                        

                                        <ReactToPrint
                                          trigger={() => (
                                            <a className="ant-btn ant-btn-primary" href="#">
                                              {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                                            </a>
                                          )}
                                          content={() => this.componentRef}
                                          />
                                                                
                                  </div>*/}
                        
                                    {parseInt(this.state.idsoc) == 5 ?

                                    <p style={{textAlign:'left', margin:"20px", marginTop:"20px"}} >{parse(this.state.dataextract)}</p>     

                                    :

                                    null

                                    }

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                          
                                      
                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>
                                
                                
                                {this.state.speaker1.length>0 ?
                                
                                  <div className="row">
                                    <div className="col-6 column">

                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',marginLeft:"25%"}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>
                                      
                                      <div className="d-flex flex-row" style={{marginTop:"20px"}}>

                                        <div>
                                          <PieChart width={350} height={200}
                                              margin={{
                                                top: 0,
                                                right: 0,
                                                left: 0,
                                                bottom: 20,
                                              }}
                                            >                                     
                                          <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                            {data.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                              
                                            ))}
                                            <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:"black"}} fill="#000"/>
                                            {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                          </Pie>
                                          <Tooltip/>
                                          {/*<Legend />*/}
                                        </PieChart>
                                        </div>

                                        <div>
                                          <BarChart width={350} height={200} 
                                              margin={{
                                                top: 0,
                                                right: 60,
                                                left: 20,
                                                bottom: 5
                                              }}
                                              data={Object.values(this.state.speaker1)}
                                            >
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="starttime" />
                                              <YAxis domain={[0, 100]}/>
                                              <Tooltip />
                                              <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                            </BarChart>
                                        </div>

                                      </div>
                                      
                                      <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',marginLeft:"25%"}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>

                                      <div className="d-flex flex-row" style={{marginTop:"20px"}}>

                                        <div>
                                          <PieChart width={350} height={250}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >          
                                            <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>

                                        <div>
                                          <BarChart width={350} height={250} 
                                                margin={{
                                                  top: 0,
                                                  right: 60,
                                                  left: 20,
                                                  bottom: 5
                                                }}
                                                data={Object.values(this.state.speaker2)}
                                              >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="starttime" />
                                                <YAxis domain={[0, 100]}/>
                                                <Tooltip />
                                                <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                              </BarChart>
                                        </div>
                                      
                        
                                      </div>

                                    </div>

                                    <div className="col-6 reponsediarization" >

                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>

                                    {this.state.speakers.map((row) => (

                                        <div key={row.iddiarization} style={{marginTop:'20px'}} >

                                            <div> {parseInt(row.speaker) == 1 ?
                                                                    
                                              <div  id={parseInt(row.iddiarization)}>

                                                  <div style={{textAlign:'center'}}>
                                                    <p >{row.starttime}</p>
                                                  </div>

                                                      <div className="container">

                                                          <div className="row">
                                                            
                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champnegatifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.negatif)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champneutresentimentmodalmobile">
                                                                {parseInt(parseFloat(row.neutre)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champpositifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.positif)*100)}%
                                                              </p>
                                                            </div>

                                                          </div>

                                                        </div>

                                                <div className="row">

                                                  <div className="col-11">
                                                    <div style={{width:'100%', textAlign:'left'}} className='textleft'>
                                                      <p style={{lineHeight:'20px', paddingRight:"30px"}}>{row.phrase}</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-1">
                                                    {row.sentimentspeaker ==='positive' ?

                                                    <div className="btnpositifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>
                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='neutral' ?

                                                    <div className="btnneutremodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='mixed' ?

                                                    <div className="btnmixed btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='negative' ?

                                                    <div className="btnnegatifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                  </div>

                                              </div>

                                              <hr className="lineseparator"></hr>                                               
                                                
                                            </div>

                                              :
                                                                    
                                            <div  id={parseInt(row.iddiarization)}>

                                              <div className="container">

                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                  <p >{row.starttime}</p>
                                                </div>

                                                    <div className="container">

                                                        <div className="row">

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champnegatifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champneutresentimentmodalmobile">
                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champpositifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                            </p>
                                                          </div>

                                                        </div>

                                                    </div>

                                              </div>

                                              <div className="row">

                                                                                            
                                                <div className="col-1">
                                                  {row.sentimentspeaker ==='positive' ?

                                                  <div className="btnpositifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>
                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='neutral' ?

                                                  <div className="btnneutremodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='mixed' ?

                                                  <div className="btnmixed btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='negative' ?

                                                  <div className="btnnegatifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }
                                                  
                                                </div>

                                                <div className="col-11">
                                                  <div style={{width:'100%', textAlign:'left'}} className='textright'>
                                                    <p style={{lineHeight:'20px', paddingLeft:"20px"}}>{row.phrase}</p>
                                                  </div>
                                                  
                                                </div>

                                            </div>    

                                            <hr className="lineseparator"></hr>                        
                                                                                                    
                                            </div>

                                          }
                                          
                                          </div>

                                          
                                        </div>

                                        ))}

                                    </div>
                                  </div>

                                  :

                                  null

                                }

                            <div class="container">
                              <div class="row">
                                <div class="col-sm">
                                  
                                </div>
                                <div class="col-sm">
                                  <div className="audioplayermodal">
                                  <AudioPlayer src={this.state.soundlink} />
                                  {/** 
                                  <ReactAudioPlayer
                                    src={this.state.soundlink}
                                    controls
                                    volume
                                    
                                    />
                                  */}
                                  </div>
                                </div>
                                <div class="col-sm">
                                  
                                </div>
                              </div>
                            </div>

                            </div>                      

                        </BrowserView>

                        <MobileView>

                                  <div >
                                    <button className="btn btntransparent" style={{marginTop:"-5px",marginLeft:"90%"}} onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                                  </div>

                                  <div className="d-flex align-items-center justify-content-center">
                                    <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                  </div>

                                  <div className="d-flex align-items-center justify-content-center">
                                    <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center', color:"#2A83E8"}}>"{this.state.soundname}"</p>
                                  </div>

                                  <div style={{width:"90%", position:"fixed", bottom:0, zIndex:999999}}>
                                
                                    <AudioPlayer src={this.state.soundlink} />
                                    
                                  </div>

                                {/** 
                                <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000', backgroundColor:"white"}}>

                            

                                <div >
                                  <button className="btn btntransparent" style={{marginTop:"-5px",marginLeft:"90%"}} onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                                </div>

                                <p style={{color:'#2A83E8', fontWeight:'bold', textAlign:"center"}}>{this.state.lng ==="FR" ? 'Relire ' : 'Replay '} {this.state.soundname}</p>

                                <div style={{width:"90%", marginLeft:"5%",position:"fixed", bottom:0}}>
                                      
                                  <AudioPlayer src={this.state.soundlink} />
                                  
                                </div>

                                


                            </div>
                            */}

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', marginTop:'40px', textAlign:"center"}}>{this.state.soundname}</p>*/}
                            <p style={{color:'black',textAlign:"left",marginTop:'20px' }}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>

                            <div className="row">

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          
                                        </div>

                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                        
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>

                                <p style={{color:'black', marginTop:'20px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >{this.state.speaker1name}</p>                           
                                          
                                <div className='listesentiment'>
                                  <PieChart width={350} height={300}
                                        margin={{
                                          top: 0,
                                          right: 0,
                                          left: 0,
                                          bottom: 20,
                                        }}
                                      >                                     
                                    <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                      {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                        
                                      ))}
                                      <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                      {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                    </Pie>
                                    <Tooltip/>
                                    {/*<Legend />*/}
                                  </PieChart>
                                </div>

                                <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>

                                <div className='listesentiment' style={{marginTop:"20px"}}> 

                                  {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis dataKey="starttime" />
                                    <YAxis domain={[0.00, 1.00]}/>
                                    <Tooltip />
                                    <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                    <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                    <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                      </LineChart>*/}
                                    
                                    <BarChart width={350} height={300} 
                                    margin={{
                                      top: 0,
                                      right: 60,
                                      left: 20,
                                      bottom: 5
                                    }}
                                    data={Object.values(this.state.speaker1)}
                                  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="starttime" />
                                    <YAxis domain={[0, 100]}/>
                                    <Tooltip />
                                    <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                  </BarChart>

                                </div>
                                
                                {/* 
                                  <hr className="lineseparatorspeaker"></hr>
                                */}

                                {this.state.speaker2.length>0 ?

                                <div>

                                    <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'40px'}} >{this.state.speaker2name}</p>                       
                                        
                                        <div className='listesentiment'>
                                          <PieChart width={350} height={300}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >          
                                            <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>

                                        <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>

                                        <div className='listesentiment' style={{marginTop:"20px"}}> 

                                        <BarChart width={350} height={300} 
                                            margin={{
                                              top: 0,
                                              right: 60,
                                              left: 20,
                                              bottom: 5
                                            }}
                                            data={Object.values(this.state.speaker2)}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="starttime" />
                                            <YAxis domain={[0, 100]}/>
                                            <Tooltip />
                                            <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                          </BarChart>

                                        </div>

                                </div>
                                

                                :

                                null

                                }

                                {this.state.speaker1.length>0 ?

                                  <div>

                                      <div >
                                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em', textAlign:'center', marginTop:'20px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                      </div>

                                      {this.state.speakers.map((row) => (

                                        <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                            <div> {parseInt(row.speaker) == 1 ?
                                                                    
                                              <div  id={parseInt(row.iddiarization)}>

                                                  <div style={{textAlign:'center'}}>
                                                    <p >{row.starttime}</p>
                                                  </div>

                                                      <div className="container">

                                                          <div className="row">
                                                            
                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champnegatifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.negatif)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champneutresentimentmodalmobile">
                                                                {parseInt(parseFloat(row.neutre)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champpositifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.positif)*100)}%
                                                              </p>
                                                            </div>

                                                          </div>

                                                        </div>

                                                <div className="row">

                                                  <div className="col-11">
                                                    <div style={{width:'100%', textAlign:'left'}} className='textleft'>
                                                      <p style={{lineHeight:'20px', paddingRight:"30px"}}>{row.phrase}</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-1">
                                                    {row.sentimentspeaker ==='positive' ?

                                                    <div className="btnpositifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>
                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='neutral' ?

                                                    <div className="btnneutremodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='mixed' ?

                                                    <div className="btnmixed btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='negative' ?

                                                    <div className="btnnegatifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                  </div>

                                              </div>

                                              <hr className="lineseparator"></hr>                                               
                                                
                                            </div>

                                              :
                                                                    
                                            <div  id={parseInt(row.iddiarization)}>

                                              <div className="container">

                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                  <p >{row.starttime}</p>
                                                </div>

                                                    <div className="container">

                                                        <div className="row">

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champnegatifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champneutresentimentmodalmobile">
                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champpositifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                            </p>
                                                          </div>

                                                        </div>

                                                    </div>

                                              </div>

                                              <div className="row">

                                                                                            
                                                <div className="col-1">
                                                  {row.sentimentspeaker ==='positive' ?

                                                  <div className="btnpositifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>
                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='neutral' ?

                                                  <div className="btnneutremodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='mixed' ?

                                                  <div className="btnmixed btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='negative' ?

                                                  <div className="btnnegatifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }
                                                  
                                                </div>

                                                <div className="col-11">
                                                  <div style={{width:'100%', textAlign:'left'}} className='textright'>
                                                    <p style={{lineHeight:'20px', paddingLeft:"20px"}}>{row.phrase}</p>
                                                  </div>
                                                  
                                                </div>

                                            </div>    

                                            <hr className="lineseparator"></hr>                        
                                                                                                    
                                            </div>

                                          }
                                          
                                          </div>

                                          
                                        </div>

                                        ))}

                                    <div style={{height: isBrowser?"0px":"60px"}}>

                                      <p style={{fontSize:"0px"}}>.</p>

                                    </div>


                                  </div>

                                  :

                                  null

                                }

                        </MobileView>

                      </div>

                      :

                      <div className='modaldetailsdata' >

                        {this.state.speakers.length>0?

                        <div style={{margin:"20px"}}>

                            <div style={{position:"fixed", right:'10px', top:10, zIndex: '1000'}}>
                              <button  className="btn btntransparent" onClick={() => this.setState({isModaldetails:false,speaker1:[], typetranscription:""})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                            </div>

                            {isBrowser?
                            
                                <div className="row d-flex align-items-center justify-content-center">

                                  <div className="col-3 d-flex align-items-center justify-content-center">
                                    <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                  </div>

                                  <div className="col-4 d-flex align-items-start justify-content-center" style={{marginTop:"40px"}}>
                                    <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>{this.state.lng ==="FR" ? 'Compte rendu et transcription de l\'enregistrement de l\'audio: ' : 'Meeting report and transcription of audio recording: '}</p>
                                  </div>

                                  <div className="col-9 d-flex align-items-start justify-content-start">
                                    <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>"{this.state.soundname}"</p>
                                  </div>

                                  <div style={{width:"400px", position:"fixed",marginTop:"100px"}}>
                                
                                    <AudioPlayer src={this.state.soundlink} />
                                    
                                  </div>


                                </div>

                          
                                :

                                <div className="d-flex flex-column align-items-center justify-content-center">

                                  <div className="d-flex align-items-center justify-content-center">
                                    <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                  </div>

                                  <div className="d-flex align-items-start justify-content-center">
                                    <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>{this.state.lng ==="FR" ? 'Compte rendu et transcription de l\'enregistrement de l\'audio: ' : 'Meeting report and transcription of audio recording: '}</p>
                                  </div>

                                  <div className="d-flex align-items-start justify-content-start">
                                    <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>"{this.state.soundname}"</p>
                                  </div>

                                  <div style={{width:"90%", marginLeft:"5%",position:"fixed", bottom:0, zIndex:999999}}>
                                
                                    <AudioPlayer src={this.state.soundlink} />
                                    
                                  </div>

                                </div>

                            }
                            
                            {/** 
                            <div className="audioplayermodalcr">
                                
                                <AudioPlayer src={this.state.soundlink} />
                                
                            </div>
                            
                            <div className="audioplayermodalcr">

                              <ReactAudioPlayer
                              src={this.state.soundlink}
                              controls
                              volume
                              />

                            </div>
                            */}
                           

                            {/*
                            <div className="col-4 d-flex align-items-start justify-content-start" style={{marginTop:"40px"}}>
                              <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Compte rendu: ' : 'Meeting report: '}</p>
                            </div>
                            */}

                            <p style={{textAlign:'left'}} >{parse(this.state.summarization.replaceAll('\\n', '<br/><br/>'))!=="null"? parse(this.state.summarization.replaceAll('\\n', '<br/><br/>')):<p>{this.state.lng ==="FR" ? 'veuillez réessayer s\'il vous PlagiarismTwoTone.:' : 'Please retry again'}</p>}</p>     

                            <div className="col-4 d-flex align-items-start justify-content-start">
                              <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Transcription: ' : 'Transcription: '}</p>
                            </div>

                            {this.state.speakers.map((row) => (

                                <p key={row.iddiarization } style={{textAlign:'left'}} >Speaker {row.speaker} ({row.starttime}) : {row.phrase}</p>

                            ))}

                          <div style={{height: isBrowser?"0px":"60px"}}>

                            <p style={{fontSize:"0px"}}>.</p>

                          </div>

                        </div>

                        :

                        null

                        }


                      </div>


                    }

                    

                </Modal.Body>
                </Modal>

                </div>

                :

                null

              }

              {this.state.isModalupdate === true ?

              <Modal

              show={this.state.isModalupdate}
              onHide={ () => this.setState({isModalupdate:false,thematique :'', idaudioselect :0 , speaker1: '', speaker2: '',soundlink: '', soundname : '',comment:''})}
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                    <div style={{marginLeft:"90%"}}>
                      <button  className="btn btntransparent" onClick={() => this.setState({isModalupdate:false,thematique :'', idaudioselect :0 , speaker1: '', speaker2: '',soundlink: '', soundname : '',comment:''})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                    </div>

                <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:isBrowser?'1.5em':'1em',textAlign:"center", margin:0}}>{this.state.soundname}</p>


                

                {/** 
                <div className={isBrowser?"audioplayermodal":"audioplayermodalmobile"}>

                <AudioPlayer src={this.state.soundlink} />
                  
                  <ReactAudioPlayer
                    src={this.state.soundlink}
                    controls
                    volume
                    
                    />
                 
                </div>
                 */}

                  <div className="d-flex flex-column align-items-left">

                     

                      {/*
                      <p style={{fontWeight:"bold", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique:'}&nbsp;</p>
                      
                      <label>
                        <textarea type="textarea" name="thematique" rows={3} cols={50} className="w-100" value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                      </label>
                       */}
                      
                                          
                      <p style={{fontWeight:"bold", textAlign:"left"}}>{this.state.lng ==="FR" ? 'Nom de l\'audio:' : 'Sound name:'}&nbsp;</p>
                      <input style={{marginTop:"-20px"}} type="text" className="w-100" value={this.state.soundname} onChange={(e) => this.setState({ soundname: e.target.value})}/>
                      
                      {this.state.typetranscription == "sentiment"?

                        <div>

                          <p style={{fontWeight:"bold",marginTop:"20px", textAlign:"left"}}>Speaker 1  :&nbsp;</p>
                          <input  style={{marginTop:"-20px"}} className="w-100" type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                          <p style={{fontWeight:"bold",marginTop:"20px", textAlign:"left"}}>Speaker 2  :&nbsp;</p>
                          <input  style={{marginTop:"-20px"}} className="w-100" type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>

                        </div>

                        :

                        null
                      
                      }
                                                       
                      <p style={{fontWeight:"bold", textAlign:"left",marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Commentaire:' : 'Comment:'}&nbsp;</p>
                      <textarea style={{marginTop:"-20px"}} type="textarea" rows={3} className="w-100" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value})}/>

                      <div style={{width:"90%", marginLeft:"5%", marginTop:"20px"}}>
                                      
                        <AudioPlayer src={this.state.soundlink} />
                        
                      </div>

                    <div style={{marginTop:"20px" }} className="d-flex flex-row align-items-center justify-content-center">
                                      
                          <Button className="btn" style={{ marginRight:"20px", width:'130px'}} onClick={ () => this.setState({isModalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                          <Button className="btn" style={{  width:'130px'}} onClick={() => this.updatesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                      </div>

                  </div>

              </Modal.Body>
              </Modal>

              :

              null

              }

              {this.state.isModaladd === true ?

              <Modal

              show={this.state.isModaladd}
              onHide={ () => this.setState({isModaladd:false,selectedFile:null,soundfilename:null,soundlng:"",speakernumber:2})}
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                
                    <div style={{marginLeft:"90%"}}>
                      <button  className="btn btntransparent" onClick={() => this.setState({isModaladd:false,selectedFile:null,soundfilename:null,soundlng:"",speakernumber:2})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                    </div>
                                  
                    <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                        <label htmlFor="filePicker" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> {this.state.lng ==='FR'? 'Choisir fichier': 'Choose file'} </label>
                        <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept="audio/*,.gsm,.mp4,.mov,.mpeg,.avi,.m4a"/>

                        {this.state.soundfilename === null ?

                          <div >
                              <p style={{marginBottom:"20px"}}>{this.state.lng ==='FR'? 'Aucun fichier selectionné': 'No file chosen'}</p>                 
                          </div>

                          :

                          <div>

                          <p style={{textAlign:"center"}}>{this.state.soundfilename}</p>

                          <p style={{marginBottom:"-10px", marginTop:"20px", textAlign:"center"}}>{this.state.lng ==='FR'? 'Langue de l\'audio': 'Audio language'}  :</p>
                          <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                            <Form.Select style={{height:'40px', width:'250px' }} value={this.state.soundlng} onChange={this.selectlng}>
                            <option value=""></option>
                              {this.state.listlng.map((lng) => (
                                <option value={lng.lng}>{this.state.lng ==='FR'? lng.languefr: lng.langueen}</option>
                              ))}
                            </Form.Select>
                          </div>

                          {this.state.soundlng !== '' ?

                              <div>
                              
                                <p style={{marginBottom:"-10px", marginTop:"20px", textAlign:"center"}}>{this.state.lng ==='FR'? 'Type de transcription': 'Transcription type'}  :</p>
                                <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                                  <Form.Select style={{height:'40px', width:'250px' }} value={this.state.typetranscription} onChange={this.selecttypetranscription} disabled={this.state.typesubscription === "free" ? true : false}>
                                    
                                    <option value=""></option>

                                    {this.state.typesubscription === "free"?

                                        null

                                      :

                                      <option value="sentiment">{this.state.lng ==='FR'? "Analyse de sentiment": "Sentiment analysis"}</option>
                                      
                                    }
                                    
                                    <option value="compterendu">{this.state.lng ==='FR'? "Compte rendu de réunion": "Meeting report"}</option>
                                    
                                  </Form.Select>
                                </div>

                                <p style={{marginBottom:"-10px", marginTop:"20px", textAlign:"center"}}>{this.state.lng ==='FR'? 'Nombre d\'interlocuteur': 'Number of interlocutors'}  :</p>
                                <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                                  <Form.Select style={{height:'40px', width:'250px' }} value={this.state.speakernumber} onChange={this.selectnumber} disabled={this.state.typesubscription === "free" ? true : false}>
                                  
                                    {numberspeaker.map((spkint) => (
                                      <option value={spkint.id}>{spkint.value}</option>
                                    ))}
                                    {/*<option value={20}>{this.state.lng ==='FR'? 'Illimité': 'Unlimited'} </option>*/}
                                  
                                  </Form.Select>
                                </div>

                                
                              </div>

                              :

                              null

                            }

                          </div>

                        }

                        {this.state.typetranscription !== '' ?
                          
                          <div>

                            <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                              <button onClick={this.onFileUpload} style={{marginTop:'20px'}} className="btn btn-primary"> 
                                {this.state.lng ==='FR'? 'ENVOYER VERS UNSAID ': 'UPLOAD TO UNSAID'} 
                              </button>
                            </div> 

                          </div>

                          :

                          null

                        }

                        {this.state.loadershow === true ?
                      
                          <div style={{margin:'10px'}} className='d-flex flex-column justify-content-center'>
                            <p style={{ marginTop:"20px", textAlign:"center", color:'#2A83E8', fontWeight:"bold", fontSize:"24px"}}>{this.state.lng ==='FR'? 'Veuillez patienter ...': 'Please wait ...'}</p>
                            <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />
                            <p style={{ marginTop:"20px", textAlign:"center", color:'#2A83E8', fontWeight:"bold", fontSize:"24px"}}>{this.state.lng ==='FR'? 'Chargement en cours ...': 'Loading data ...'}</p>

                          </div>
                            
      
                          :
    
                          null
    
                        }
     
                    </div>

              </Modal.Body>
              </Modal>

              :

              null

              }

              {this.state.isModaldelete === true ?

              <Modal

              show={this.state.isModaldelete}
              onHide={ () => this.setState({isModaldelete:false,soundname :'', idaudioselect :0})}
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                <div className="d-flex justify-content-end">
                  <button  className="btn btntransparent" onClick={() => this.setState({isModaldelete:false,soundname :'', idaudioselect :0})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                </div>
                            
                <div className="d-flex flex-row justify-content-center">
                                  
                    <div className="d-flex flex-column" style={{margin:"20px"}}>
                        
                        <div className="d-flex flex-column">
                            <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==='FR'? 'Voulez-vous supprimer': 'Would you like to delete'} &nbsp;</p>
                            <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.soundname} ?&nbsp;</p>
                            <form>

                            
                            
                          </form>
                        </div>
                        
                    </div>

                    

                </div>  

                <div className='d-flex flex-row justify-content-center'>
                                        
                      <Button className="btn"style={{ margin:"10px", width:'100px'}} onClick={ () => this.setState({isModaldelete:false,soundname :'', idaudioselect :0})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                      <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.deletesound()} variant="danger">{this.state.lng ==='FR'? 'Supprimer': 'Delete'}</Button>

                </div>  

              </Modal.Body>
              </Modal>

              :

              null

              }

              {this.state.isModalthematique === true ?

                  <Modal
                  
                  show={this.state.isModalthematique}
                  onHide={ () => this.setState({isModalthematique:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>

                    <div className="d-flex justify-content-end">
                      <button  className="btn btntransparent" onClick={() => this.setState({isModalthematique:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                    </div>
                                
                    <div className="d-flex flex-row">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique'}&nbsp;</p>
                                <form>
                                <label>
                                  <textarea type="textarea" name="thematique" rows={5} style={{width:'400px'}} value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                                </label>
                                
                                <div className='row text-white d-flex align-items-center'>
                                            
                                    <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalthematique:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.themeatiquesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

              }

              {this.state.isModaltranscription === true ?

              <Modal

              show={this.state.isModaltranscription}
              /*onHide={ () => this.setState({isModaltranscription:false})}*/
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                    <div className="d-flex justify-content-end">
                      <button  className="btn btntransparent" onClick={() => this.setState({isModaltranscription:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                    </div>
                                  
                    <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                        {this.state.loadershow === true ?
                      

                          <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                            

                          :

                          null

                        }
                
                        
                    </div>

              </Modal.Body>
              </Modal>

              :

              null

              }

              {this.state.isModalspeaker === true ?

              <Modal
              
              show={this.state.isModalspeaker}
              onHide={ () => this.setState({isModalspeaker:false})}
              dialogClassName="modal-dialog"
              aria-labelledby="example-custom-modal-styling-title" centered
              >
                <Modal.Body>

                <div className="d-flex justify-content-end">
                  <button  className="btn btntransparent" onClick={() => this.setState({isModalspeaker:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                </div>
                            
                <div className="d-flex flex-row">
                                  
                    <div className="d-flex flex-column" style={{margin:"20px"}}>
                        
                        <div className="d-flex flex-column">
                            <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>SPEAKER :&nbsp;</p>
                            <form>
                            
                            <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                            <input  type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                            <p>&nbsp;</p>
                            <p style={{fontWeight:"bold"}}>Speaker 2  :&nbsp;</p>
                            <input  type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                            
                            <div className='row text-white d-flex align-items-center'>
                                        
                                  <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalspeaker:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                  <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.speakermodalsound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                            </div>
                            
                          </form>
                        </div>
                        
                    </div>

                </div>    

              </Modal.Body>
              </Modal>

              :

              null

              }


{this.state.speaker1.length>0?

<div ref={el => (this.componentRef = el)} id="datatoprint" style={{marginTop:"20%"}}>


  {this.state.typetranscription === "sentiment"?

    <div>

    <div className="container" style={{marginTop:"20px"}}>

    <div className="row d-flex align-items-center justify-content-center">

      <div className="col-3 d-flex align-items-center justify-content-center">
        <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
      </div>

      <div className="col-4 d-flex align-items-center justify-content-center">
        <p style={{ fontSize:"16px",textAlign:"center"}}>{this.state.soundname}</p>
      </div>

      <div className="col-3 d-flex align-items-center justify-content-center">
        {/*<p style={{ fontSize:"20px",textAlign:"center", color:"#2A83E8"}}>{this.state.lng ==="FR" ? 'LOGO ENTREPRISE UTILISATRICE' : 'ENTERPRISE LOGO USER'}</p>*/}
        {/*<img src={process.env.PUBLIC_URL + 'images/logoentreprise.png'} style={{ width:"150px",height:'150px'}}/>*/}
      </div>

    </div>

    </div>


                  
    <div >

      <div className="col-sm thematiquevalue" style={{width:'92%', marginLeft:'4%', marginRight:'4%', marginTop:'4%'}}>
        <div >

            {/*<div style={{width:'18%'}}>
            <img src={logo} style={{width:'100px', height:'70px'}}/>
            <span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>
            </div>
            */}

            <div className="d-flex flex-column align-items-center ">
              {/*<p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>*/}
              {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
              {/*<hr className="lineseparator"></hr>*/}
              <p style={{color:'white',textAlign:"justify", padding:"10px"}}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>
            </div>

        </div>
        
      </div>

      {parseInt(this.state.idsoc) == 5 ?

        <div style={{marginLeft:"15px",marginRight:"40px"}}>
          <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center", marginTop:"30px"}}>{this.state.lng ==="FR" ? 'Fichier projet' : 'Project sheet'}</p>
          <p style={{textAlign:'left', marginLeft:"20px",marginRight:"40px",marginTop:"20px"}} >{parse(this.state.dataextract)}</p>  
        </div>
          

        :

        null

      }

      {/*<div className="col-sm">
        
        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>

            

            <ReactToPrint
              trigger={() => (
                <a className="ant-btn ant-btn-primary" href="#">
                  {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                </a>
              )}
              content={() => this.componentRef}
              />
                                    
      </div>*/}

      <div className="col-sm" >

      <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

        <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

          <div className="champnegatifmodal" style={{padding:'5px'}}>

            <div className="row">
              <div className="col">
                
                <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakernegatif}%</p>

              </div>
              <div className="col">
                <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
              </div>
            </div>
          
              
          
          </div>

          <div className="champneutremodal" style={{padding:'5px'}}>

            <div className="row">
              <div className="col">
                
                <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerneutre}%</p>

              </div>
              <div className="col">
                <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
              </div>
            </div>
            
          </div>

          <div className="champpositifmodal" style={{padding:'5px'}}>

            <div className="row">
              <div className="col">
                
                <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                <p style={{fontSize:"1.2em",marginTop:"0px"}}>{this.state.speakerpositif}%</p>

              </div>
              <div className="col">
                <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
              </div>
            </div>
          
          </div>

        </div>

      </div>

    </div>

    {this.state.speaker1.length>0 ?

    <div >

      <div className="container">

        <div className="row">

          <div className="col-6">

            <p style={{color:'black', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center', height:"60px"}} >S1 : {this.state.speaker1name}</p>                           
              
              <div >
                <PieChart width={400} height={250}
                    
                    >                                     
                  <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                      
                    ))}
                    <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                    {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                  </Pie>
                  <Tooltip/>
                  {/*<Legend />*/}
                </PieChart>
              </div>

              <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>

              <div style={{marginTop:"20px"}}> 

                {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="starttime" />
                  <YAxis domain={[0.00, 1.00]}/>
                  <Tooltip />
                  <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                  <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                  <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                    </LineChart>*/}
                  
                  <BarChart width={300} height={250} 
                
                  data={Object.values(this.state.speaker1)}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="starttime" />
                  <YAxis domain={[0, 100]}/>
                  <Tooltip />
                  <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                  <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                  <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                </BarChart>

              </div>
              
          </div>

          <div className="col-6" >

            {this.state.speaker2.length>0 ?

              <div>

                <p style={{color:'#2A83E8', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center', height:"60px"}} >S2 : {this.state.speaker2name}</p>                       
            
                <div >
                  <PieChart width={400} height={250}
                        
                      >          
                    <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                        
                      ))}
                      <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                      {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                    </Pie>
                    <Tooltip/>
                    {/*<Legend />*/}
                  </PieChart>
                </div>

                <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>

                <div  style={{marginTop:"20px"}}> 

                <BarChart width={300} height={250} 
                  
                    data={Object.values(this.state.speaker2)}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="starttime" />
                    <YAxis domain={[0, 100]}/>
                    <Tooltip />
                    <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                    <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                    <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                  </BarChart>

                </div>

              
              </div>
          
              :
              
              null

            }

          </div>

        </div>

      </div>
      
            

            <div >
              <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
            </div>

                {this.state.speakers.map((row) => (

                          <div style={{marginTop:'20px'}}>

                              <div> {parseInt(row.speaker) == 1 ?
                                                      
                                <div >

                                  <div className="container" >

                                    <div className="row" >

                                      <div className="col-sm">

                                      <div className="col-sm" style={{textAlign:'center'}}>
                                        <p >{row.starttime}</p>
                                      </div>

                                          <div class="container d-flex flex-column align-items-center">
                                            <div class="row">
                                              <div class="col-4">
                                                <p className="champnegatifsentimentmodal" style={{margin:'5%'}}>
                                                  {parseInt(parseFloat(row.negatif)*100)}%
                                                </p>
                                              </div>
                                              <div class="col-4">
                                                <p className="champneutresentimentmodal" style={{margin:'5%'}}>
                                                  {parseInt(parseFloat(row.neutre)*100)}%
                                                </p>
                                              </div>
                                              <div class="col-4">
                                                <p className="champpositifsentimentmodal" style={{margin:'5%'}}>
                                                  {parseInt(parseFloat(row.positif)*100)}%
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                      </div>
                                      
                                      <div className="col-sm">
                                        
                                      </div>

                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>

                                    <div className="col-11">
                                      <div style={{width:'100%', textAlign:'left', marginLeft:'20px'}} className='textleft'>
                                        <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                      </div>
                                    </div>

                                    <div className="col-1">
                                      {row.sentimentspeaker ==='positive' ?

                                      <div className="btnpositifmodal btn-sentiment leftunsaid">
                                        <div className="centerunsaid">S1</div>
                                      </div>
                                      :

                                      null

                                      }

                                      {row.sentimentspeaker ==='neutral' ?

                                      <div className="btnneutremodal btn-sentiment leftunsaid">
                                        <div className="centerunsaid">S1</div>
                                      </div>

                                      :

                                      null

                                      }

                                      {row.sentimentspeaker ==='mixed' ?

                                      <div className="btnmixed btn-sentiment leftunsaid">
                                        <div className="centerunsaid">S1</div>
                                      </div>

                                      :

                                      null

                                      }

                                      {row.sentimentspeaker ==='negative' ?

                                      <div className="btnnegatifmodal btn-sentiment leftunsaid">
                                        <div className="centerunsaid">S1</div>
                                      </div>

                                      :

                                      null

                                      }

                                    </div>

                                </div>

                                <hr className="lineseparator"></hr>                                               
                                  
                              </div>

                                :
                                                      
                              <div  >

                                <div className="container">

                                  <div className="row">

                                    <div className="col-sm">

                                    </div>
                                    
                                    <div className="col-sm" style={{textAlign:'center'}}>
                                      <p >{row.starttime}</p>
                                    </div>

                                    <div className="col-sm">
                                      <div class="container d-flex flex-column align-items-center">
                                          <div class="row">
                                            <div class="col">
                                              <p className="champnegatifsentimentmodal" style={{margin:'5%'}}>
                                                {parseInt(parseFloat(row.negatif)*100)}%
                                              </p>
                                            </div>
                                            <div class="col">
                                              <p className="champneutresentimentmodal" style={{margin:'5%'}}>
                                                {parseInt(parseFloat(row.neutre)*100)}%
                                              </p>
                                            </div>
                                            <div class="col">
                                              <p className="champpositifsentimentmodal" style={{margin:'5%'}}>
                                                {parseInt(parseFloat(row.positif)*100)}%
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                    </div>

                                  </div>

                                </div>

                                <div className="row" style={{marginTop:"20px"}}>

                                
                                  
                                  <div className="col-1">
                                    {row.sentimentspeaker ==='positive' ?

                                    <div className="btnpositifmodal btn-sentiment rightunsaidprint">
                                      <div className="centerunsaid">S2</div>
                                    </div>
                                    :

                                    null

                                    }

                                    {row.sentimentspeaker ==='neutral' ?

                                    <div className="btnneutremodal btn-sentiment rightunsaidprint">
                                      <div className="centerunsaid">S2</div>
                                    </div>

                                    :

                                    null

                                    }

                                    {row.sentimentspeaker ==='mixed' ?

                                    <div className="btnmixed btn-sentiment rightunsaidprint">
                                      <div className="centerunsaid">S2</div>
                                    </div>

                                    :

                                    null

                                    }

                                    {row.sentimentspeaker ==='negative' ?

                                    <div className="btnnegatifmodal btn-sentiment rightunsaidprint">
                                      <div className="centerunsaid">S2</div>
                                    </div>

                                    :

                                    null

                                    }
                                    
                                  </div>

                                  <div className="col-10" >
                                    <div style={{width:'100%', textAlign:'center'}} className='textright'>
                                      <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                    </div>
                                    
                                  </div>

                              </div>    

                              <hr className="lineseparator"></hr>                        
                                                                                      
                              </div>

                            }
                            
                            </div>

                            
                          </div>

                ))}

              <div style={{height:'50px'}} id="lastline">
                  <p style={{color:'transparent'}}>.</p>
              </div>

                                                                                                            
              </div>

              :

              null

              }



        </div>

    :

    <div>

      <div className='modaldetailsdata' >

      {this.state.speakers.length>0?

      <div style={{margin:"20px"}}>

          <div className="col-4 d-flex align-items-start justify-content-center" style={{marginTop:"40px"}}>
            <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>{this.state.lng ==="FR" ? 'Compte rendu avec transcription de l\'enregistrement ' : 'Meeting report with transcription of recording '}"{this.state.soundname}"</p>
          </div>

          {/* 
          <div className="col-9 d-flex align-items-start justify-content-center">
            <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>"{this.state.soundname}"</p>
          </div>

          <div className="row d-flex align-items-center justify-content-center">
          
              <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>{this.state.lng ==="FR" ? 'Audio: ' : 'Audio: '}{this.state.soundname}</p>
           
          </div>

         
          <div className="col-4 d-flex align-items-start justify-content-start" style={{marginTop:"40px"}}>
            <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Compte rendu: ' : 'Meeting report: '}</p>
          </div>
          */}

          <p style={{textAlign:'left'}} >{parse(this.state.summarization.replaceAll('\\n', '<br/><br/>'))!=="null"? parse(this.state.summarization.replaceAll('\\n', '<br/><br/>')):<p>{this.state.lng ==="FR" ? 'veuillez réessayer s\'il vous PlagiarismTwoTone.:' : 'Please retry again'}</p>}</p>     

          <div className="col-4 d-flex align-items-start justify-content-start">
            <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Transcription: ' : 'Transcription: '}</p>
          </div>

          {this.state.speakers.map((row) => (

              <p key={row.iddiarization } style={{textAlign:'left'}} >Speaker {row.speaker} ({row.starttime}) : {row.phrase}</p>

          ))}

          <div className="row d-flex align-items-center justify-content-center">
          
            <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center', marginTop:"40px"}}>Powered by UNSAID</p>
       
          </div>

      </div>

      :

      null

      }


      </div>


    </div>

  }


  </div>



:


null

}



           
            
            </div>

            }

          </div>

              
               
          );
        }
    
}

export default Transcription;
