
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../App.css';
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import parse from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import Card from 'react-bootstrap/Card';
import { BrowserView, MobileView, isBrowser} from "react-device-detect";
import ReactPaginate from 'react-paginate';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;


class Dataextraction extends Component{
    
    constructor(props) {
    super(props);
      this.state = {

        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        question:'',
        sentence:'',
        responseopenai:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        lng:'',
        printbutton:false,
        listdata:[],
        listsociety: [],
        iddata:0,
        idsociete:0,
      };

      this.selectsociety = this.selectsociety.bind(this);

  }

  selectsociety(e) {
    this.setState({ idsociete: e.target.value });
  }

  componentWillMount() {

    sessionStorage.removeItem( 'iconify-count');
    sessionStorage.removeItem( 'iconify-version');

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    const datauser = JSON.stringify(myDecodedToken.init).replace(/"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === 'FR'){

      document.title = "DATA EXTRACTION";
    
    }else{

      document.title = "DATA EXTRACTION";

    }

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
  
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired === true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    
    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, ""); 

    const response = await fetch(this.state.wbslink + 'listdataextraction',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/"/g, "") !== '0'){

      this.setState({ listdata:data})

    }else{
      this.setState({ loadershow: false})
    }

    const responsesociety = await fetch(this.state.wbslink + 'society',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datasociety = await responsesociety.json();

    if(JSON.stringify(datasociety).replace(/"/g, "") !== '0'){

      this.setState({ listsociety:datasociety})

    }else{
      this.setState({ loadershow: false})
    }

    document.title = "DATA EXTRACTION";
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}

async senddata() {

  this.setState({
    loadershow:true,
    printbutton:false,
  })

  if(this.state.question.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la question.',loadershow:false});
    return
  
  }

  if(this.state.question.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add question.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la conversation.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add conversation.',loadershow:false});
    return
  
  }

       // Create an object of formData 
       const formData = new FormData(); 

       formData.append("sentence",this.state.sentence.replaceAll('\n', ' '));
       formData.append("question",this.state.question.replaceAll('\n', ' '));
   
       let token = sessionStorage.getItem('tokenunsaid');
   
       const myDecodedToken = decodeToken(token);
   
       if(myDecodedToken == null){
         this.setState({ typeuser: null})
         document.location.href = '/login';
         return;
       }
       const isMyTokenExpired = isExpired(token);
   
       if(isMyTokenExpired === true){
         this.setState({ typeuser: null})
         document.location.href = '/login';
         return;
       }
   
       token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");
   
       fetch(this.state.wbslink + `aifunction`,{                  
             
         mode: 'cors',     
         method:'post',
         headers: {'Authorization': 'Bearer '+ token},
         body: formData
   
         })
   
       .then(res => {
   
         errocode = res.status;
   
         if (!res.ok) {
             
             return res.json().then(json => { throw json; });
   
         }
   
         return res.json();
   
       })
       .then(data => {
   
       if(data.length > 0){

        this.setState({
          responseopenai:JSON.stringify(data).replace(/"/g, ""),
          errorform: false,
          errormessage: '',
          loadershow:false,
          printbutton:true,
          question : this.state.question.replaceAll('\n', ' '),
          sentence : this.state.sentence.replaceAll('\n', ' '),
        })
      
   
       }else{
   
         this.setState({ listdata : [],idsociete: 0, question:''});
   
       }
   
     })
     .catch(err => {
     
       if(errocode === 401 || errocode === 0){
         
         sessionStorage.removeItem("tokenunsaid");
         document.location.href = "/login";
   
         return
   
       }
   
       alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))
   
       this.setState({
         loadershow: false
       })
   
     });  

     return;

}

adddataextraction(){

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idsoc",this.state.idsociete);
    formData.append("question",this.state.question);

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired === true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

    fetch(this.state.wbslink + `adddataextraction`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.setState({ listdata:data,idsociete: 0, question:'', modaladd: false})
  
      }else{
        this.setState({ loadershow: false})
      }

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })

  .catch(err => {
  
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

updatedataextraction(){

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("iddata",this.state.iddata);
  formData.append("question",this.state.question);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired === true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

  fetch(this.state.wbslink + `updatedataextraction`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

  if(data.length > 0){
    
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.setState({ listdata:data,idsociete: 0, question:'', modalupdate: false})

      }else{
        this.setState({ loadershow: false})
      }

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })
  .catch(err => {
  
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


}

deletedataextraction(){

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("iddata",this.state.iddata);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired === true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

  fetch(this.state.wbslink + `deletedataextraction`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.setState({ listdata:data,idsociete: 0, question:'', modaldelete: false})

      }else{
        this.setState({ loadershow: false})
      }

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })

  .catch(err => {
  
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

savedata(){

      // Create an object of formData 
    const formData = new FormData(); 

    formData.append("iddata",this.state.iddata);
    formData.append("question",this.state.question);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired === true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

    fetch(this.state.wbslink + `updatedataextraction`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.setState({ listdata:data,idsociete: 0, question:'', modalupdate: false})

        this.setState({
          errorform: false,
          errormessage: '',
          loadershow:false,
          printbutton:false,
        })


      }else{
        this.setState({ loadershow: false})
      }

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })
  .catch(err => {
  
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

   
  render() {

          return (
              
                <div className="App" style={{margin:'10%',marginTop:'120px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    <div className='d-flex justify-content-center'>
                        
                        <button style={{margin:'20px'}}onClick={() => this.setState({modaladd:true, idsociete:0, question:''})} className="btn btn-success">{this.state.lng ==="FR" ? 'Ajouter' : 'Add'}</button> 
                                                              
                      </div> 

                      {this.state.modaladd=== true ?

                      <Modal

                      show={this.state.modaladd}
                      onHide={ () => this.setState({modaladd:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">

                          <div className="form-group" style={{margin:'5px'}}>
                          <label >{this.state.lng ==="FR" ? 'Société :' : 'Society: '}</label>
                          <div>
                            <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.idsociete} onChange={this.selectsociety}>
                              {this.state.listsociety.map((soc) => (
                                <option value={soc.idsociete}>{soc.nomsociete}</option>
                              ))}
                            </Form.Select>
                          </div>
                          </div>

                          <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Fonction' : 'Function'}</label>
                          </div>

                          <div className="form-group" style={{margin:'5px'}}>
                            <textarea style={{paddingTop:'0px', paddingLeft:'10px' }} type="text" rows="10" className="form-control" value={this.state.question} onChange={(e) => this.setState({ question: e.target.value})} />                    
                          </div>

                          {this.state.errorform === true ? 

                          <Alert severity="error">
                            <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                            {this.state.errormessage}
                          </Alert>

                          :

                          null

                          }
                                    
                              <div className='d-flex flex-row justify-content-center'>
                                          
                                    <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modaladd:false, iddata:0,question:''})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.adddataextraction()} variant="success">{this.state.lng ==="FR" ? 'Ajouter' : 'Add'}</Button>

                              </div>

                          </div>          
                        
                      </Modal.Body>
                      </Modal>

                      :

                      null

                      }

                    {this.state.modalupdate === true ?

                    <Modal

                    show={this.state.modalupdate}
                    onHide={ () => this.setState({modalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    <Modal.Body>
                      <div className="d-flex flex-column justify-content-center">

                          <label style={{marginLeft:'5%'}}>{this.state.lng ==="FR" ? 'Fonction: ' : 'Function: '}</label>
                          <textarea style={{width:'90%', margin:'5%'}} type="text" rows="10" className="form-control" value={this.state.question} onChange={(e) => this.setState({ question: e.target.value})} />

                          {this.state.errorform === true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur:' : 'Error: '}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                          <div className='d-flex flex-row justify-content-center'>
                                      
                                <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false, iddata:0,question:''})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatedataextraction()} variant="success">{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</Button>

                          </div>

                      </div>          
                    
                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }

                    {this.state.modaldelete === true ?

                    <Modal

                    show={this.state.modaldelete}
                    onHide={ () => this.setState({modaldelete:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">

                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous supprimer la fonction' : 'Would you like to delete function'}</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.question}? &nbsp;</p>
                                
                                <form>

                                {this.state.errorform === true ? 

                                <Alert severity="error">
                                  <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                  {this.state.errormessage}
                                </Alert>

                                :

                                null

                                }

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false, iddata:0,question:''})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletedataextraction()} variant="danger">{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }

                    <div className='centrervue' >

                    <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                    <BrowserView>
                    <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          
                          <TableCell >{this.state.lng ==="FR" ? 'Société' : 'Society'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Function' : 'Fonction'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listdata.map((row) => (
                          <TableRow key={row.iddataextraction}>


                            <TableCell >{row.nomsociete}</TableCell>
                            <TableCell >{row.dataextractionquestion}</TableCell>
                            <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.setState({modalupdate:true, iddata:row.iddataextraction,question:row.dataextractionquestion}) }><span><EditIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.setState({modaldelete:true, iddata:row.iddataextraction,question:row.dataextractionquestion})}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </TableContainer>

                    {this.state.listdata.length>0?

                    <ReactPaginate
                    previousLabel={"<< prev"}
                    previousClassName={"prevClass"}
                    previousLinkClassName={"prevClass"}
                    nextLabel={"next >>"}
                    nextClassName={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>

                    :

                    null
                    }

                    </BrowserView>

                    <MobileView>

                    <div className='paginationmobile' style={{marginTop:"-5%"}}>

                    {this.state.listdata.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    </div>

                    <div  style={{marginTop:"5%"}}>
                      <p style={{color:"transparent"}}>.</p>
                    </div>

                    <div style={{marginTop:"40px"}}>

                      {this.state.listdata.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                      <Card.Body  key={row.iddataextraction} style={{backgroundColor: "#f5fffa"}}>

                        <Card.Text>
                            <p style={{color:"black", marginTop:"10px"}}>{row.nomsociete}</p>
                            <p style={{color:"black", marginTop:"10px"}}>{row.dataextractionquestion}</p>
                      
                        </Card.Text>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                                          
                            <button type="submit" className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdateentreprise(row.iddataextraction,row.dataextractionquestion)}><span><EditIcon /></span></button>
                            <button type="submit" className="btn btn-danger btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showdeleteentreprise(row.iddataextraction,row.dataextractionquestion)}><span><DeleteIcon/></span></button>

                          </div>
                      </Card.Body>
                      </Card>

                      ))}

                    </div>

                    </MobileView>

                    </div> 









                        <div className='d-flex justify-content-start'>
                          <label>{this.state.lng ==="FR" ? 'Fonction' : 'Function'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={3} className="form-control" value={this.state.question} onChange={(e) => this.setState({ question: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-start'>
                        <label style={{marginTop:isBrowser?"50px":"20px"}}>{this.state.lng ==="FR" ? 'Phrase' : 'Sentence'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={isBrowser?"10":"6"} className="form-control" value={this.state.sentence} onChange={(e) => this.setState({ sentence: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform === true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                     

                      
                      {this.state.printbutton === true ?

                          <div className='d-flex flex-row justify-content-center'>
        
                            <button style={{margin:'20px', marginRight:"25%"}} onClick={() =>  this.setState({printbutton : false})} className="btn btn-danger">{this.state.lng ==="FR" ? 'Annuler' : 'Cancel'}</button> 

                            <button style={{margin:'20px'}}onClick={() => this.savedata()} className="btn btn-success">{this.state.lng ==="FR" ? 'Valider' : 'Validate'}</button> 
                                                                
                          </div> 

                          :

                          <div className='d-flex justify-content-center'>
     
                            <button style={{margin:'20px'}}onClick={() => this.senddata()} className="btn btn-primary">{this.state.lng ==="FR" ? 'Submit' : 'Submit'}</button> 
                                                                
                          </div> 
                          
                      }

                      <div className='d-flex justify-content-center'>

                          {this.state.loadershow === true ?
                                

                                <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                  

                                :

                                null

                            }   
                      </div>

                      {this.state.printbutton === true ?

                            <div >

                              

                                <p style={{marginTop:isBrowser?"20px":"20px", fontSize:"30px"}}>{this.state.lng ==="FR" ? 'Résultat:' : 'Result:'}</p>

                                <ReactToPrint
                                                                  
                                  trigger={() => (
                                    <img src={process.env.PUBLIC_URL + 'images/pdf-animated.gif'} style={{ width:'150px',height:'150px'}} alt="UNSAID"/>
                                    
                                  )}

                                  documentTitle = "SOUND NAME"

                                  content={() => this.componentRef}

                                />

                                <div ref={el => (this.componentRef = el)} style={{width:'96%', marginLeft:'2%', marginRight:'2%'}}>

                                  <div >

                                    <div className="container" style={{marginTop:"20px"}}>

                                      <div className="row d-flex align-items-center justify-content-center">

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                        </div>

                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                          <p style={{fontWeight:'bold', fontSize:"20px"}}>SOUND NAME</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logoentreprise.png'} style={{ width:"150px",height:'150px'}}/>
                                        </div>

                                      </div>

                                    </div>
                  
                                  </div>

                                  <p style={{textAlign:'left'}} >{parse(this.state.responseopenai.replaceAll('\\n', '<br/><br/>'))!=="null"? parse(this.state.responseopenai.replaceAll('\\n', '<br/><br/>')):<p>{this.state.lng ==="FR" ? 'veuillez réessayer s\'il vous PlagiarismTwoTone.:' : 'Please retry again'}</p>}</p>     

                                </div>

                            </div>

                            :

                            null

                      }

                </div>
                
          );
        }
    
}

export default Dataextraction;
