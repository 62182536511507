
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import '../App.css';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import { isExpired, decodeToken }from "react-jwt";

var errocode = 0;


class Summarization extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        question:'',
        sentence:'',
        responsesummarization:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        lng:'',
        
      };

  }
 
  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === 'FR'){

      document.title = "RESUME";
    
    }else{

      document.title = "SUMMARIZATION";

    }

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  componentDidMount() { 

    if(this.state.lng === 'FR'){

      document.title = "RESUME";
    
    }else{

      document.title = "SUMMARIZATION";

    }
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}


//ENVOI DU TEXTE A ANALYSER
async senddata() {

  this.setState({
    loadershow:true,
  })

  if(this.state.sentence.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la conversation.',loadershow:false });
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add conversation.',loadershow:false });
    return
  
  }

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("sentence",this.state.sentence.replaceAll('\n', ' '));

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired === true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

  fetch(this.state.wbslink + `summarizationtools`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

  if(data.length > 0){
    
    this.setState({
      responsesummarization:JSON.stringify(data).replaceAll(/"/g, ""),
      errorform: false,
      errormessage: '',
      loadershow:false,
      sentence : this.state.sentence.replaceAll('\n', ' '),   
    })

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })
  .catch(err => {
  
    if(errocode === 401 || errocode === 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

  return;

}

   
  render() {

          return (
              
                <div className="App" style={{margin:'10%',marginTop:'120px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                        <div className='d-flex justify-content-start'>
                          <label style={{marginTop:isBrowser?"50px":"20px"}}>{this.state.lng ==="FR" ? 'Phrase' : 'Sentence'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={isBrowser?"10":"6"} className="form-control" value={this.state.sentence} onChange={(e) => this.setState({ sentence: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform === true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                     

                      <div className='d-flex justify-content-center'>
     
                        <button style={{margin:'20px'}}onClick={() => this.senddata()} className="btn btn-primary">{this.state.lng ==="FR" ? 'SUBMIT' : 'SUBMIT'}</button> 
                                                             
                      </div> 

                      <div className='d-flex justify-content-center'>

                          {this.state.loadershow === true ?
                                

                                <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                  

                                :

                                null

                            }   
                      </div>

                      <div className='d-flex justify-content-start'>
                        <label style={{marginTop:isBrowser?"50px":"20px"}}>{this.state.lng ==="FR" ? 'Résultat:' : 'Result:'}</label>
                      </div>
                      <p style={{textAlign:'left'}}>{parse(this.state.responsesummarization.replaceAll('\\n', '<br/>'))}</p>     
                      
                  
               
                </div>
                
          );
        }
    
}

export default Summarization;
