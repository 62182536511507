
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import '../App.css';
import { Modal} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");



var listdata=[];

class Listpayment extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        listpayment: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        idpayment:0,
        namepayment:'',
        latpayment:'',
        longpayment:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listuserinit: [],
        filterdata:'',
        offset: 0,
        listuserpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
        modalinfo:false,
        paymentdate:'',
        mailuser:'',
        offertype:'',
        amount:0.00,
        paypalid:'',
        nomutilisateur:'',
        nomsociete:'',
        telephoneutilisateur:'',
        idtypeuser:0,
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listpaymentpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listpayment:slice
  })

  window.scrollTo(0, 0)

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    
    this.setState({
      wbslink:wbslinkload,
    
    })

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(tokenuser);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(tokenuser);

      if(isMyTokenExpired === true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      var errocode = 0;

  let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");
      
      fetch(wbslinkload + `getdatauser`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ tokencredit},

        })

        .then(res => {

          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {
          
          if(data.length>0){

            //alert(parseInt(JSON.stringify(data[0].idtypeutilisateur).replace(/"/g, "")))
            
            this.setState({
              idtypeuser: parseInt(JSON.stringify(data[0].idtypeutilisateur).replace(/"/g, "")),
            })
    
            if(JSON.stringify(data[0].adressefacturation).replace(/"/g, "") === "null"){
              this.setState({ adresse: ""})
            }
        
        }

          if(JSON.stringify(data.status).replace(/"/g, "") === "unsubscribed" && window.location.pathname !== "/Buycredit"){

            document.location.href = "/Buycredit";

          }else{

            this.setState({
              loadingdata:false
            })
            
          }
          
      })
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired === true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/"/g, "");

    const response = await fetch(this.state.wbslink + 'listpayment',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/"/g, "") !== '0'){

      this.setState({ listpayment: data, listpaymentinit: data,listpaymentpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idpayment :item.idpayment ,name:item.name,email:item.email,telephoneutilisateur:item.telephoneutilisateur,offertype:item.offertype,nomsociete:item.nomsociete,amount:item.priceht,paypalid:item.paypalid});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listpaymentinit :data,
          listpayment:slice
      })
    
    }

    if(lnguser === "FR"){
      document.title = "Liste des paiements";
    }else{
      document.title = "List of payments";
    }

    
    const datauser = JSON.stringify(myDecodedToken.init).replace(/"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({
      idtypeuser: parseInt(userdata[1]),
    })

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght === 0){
      this.setState({listpayment: this.state.listpaymentinit})
      return;
    }
  
    const filteredItems = this.state.listpaymentinit.filter((paymentlist) => {
      return (paymentlist.name).toLowerCase().includes(e.toLowerCase()) || (paymentlist.email).toLowerCase().includes(e.toLowerCase()) || (paymentlist.telephoneutilisateur).toLowerCase().includes(e.toLowerCase()) || (paymentlist.offertype).toLowerCase().includes(e.toLowerCase()) || (paymentlist.amount).toLowerCase().includes(e.toLowerCase()) || (paymentlist.paypalid).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listpayment: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listpayment:slice,
                listpaymentpage:filteredItems,
            })
  
    }else{
  
      this.setState({listpayment: [], listpaymentpage:[]})
  
    }
   
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idpayment: 0,
    namepayment: '',
    latpayment:'',
    longpayment:'',
    textloading:''
  });
  
}


infopayment(idpayment,paymentdate,mailuser,offertype,amount,paypalid,nomutilisateur, nomsociete,telephoneutilisateur){

  this.setState({
    idpayment:idpayment,
    paymentdate:paymentdate,
    mailuser:mailuser,
    offertype:offertype,
    amount:amount,
    paypalid:paypalid,
    nomutilisateur:nomutilisateur,
    nomsociete:nomsociete,
    telephoneutilisateur:telephoneutilisateur,
    modalinfo:true,
  });

 

}


    
  render() {

          return (

              <div>

                {this.state.loadingdata === true?

                  null
                
                  :
                  
                  <div className="App" style={{marginTop:'80px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    {this.state.modalinfo === true?

                    <Modal

                    show={this.state.modalinfo}
                    onHide={ () => this.setState({modalinfo:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered

                    >
                      <Modal.Body className='cardinfopayment'>

                      <div className="d-flex justify-content-end">
                        <button  className="btn btntransparent" onClick={() => this.setState({modalinfo:false,loadershow:false,linkpaypal:""})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                      </div>

                        <div className="d-flex flex-column justify-content-start" style={{margin:"5px",fontSize:"16px", textAlign:"left"}}>

                           {parseInt(this.state.idtypeuser) === 2 ?

                            <p style={{textAlign:'center', textDecoration:'none'}}>{this.state.lng ==="FR" ? 'Offre ' : 'Offer '} <br/>{this.state.offertype.charAt(0).toUpperCase() + this.state.offertype.slice(1)}</p>

                            :

                            <p style={{textAlign:'center', textDecoration:'none'}}>{this.state.lng ==="FR" ? 'Vous avez payé l\'offre ' : 'You have paid offer '} <br/>{this.state.offertype.charAt(0).toUpperCase() + this.state.offertype.slice(1)}</p>
                           
                           } 
                          
                          <p >{this.state.lng ==="FR" ? 'Payé le ' : 'Paid on '} {parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(this.state.paymentdate.replace(/[-]/g, '/'))).getSeconds())}s</p>
                          
                          {parseInt(this.state.idtypeuser) === 2 ?

                            <div>
                              <p >{this.state.lng ==="FR" ? 'Entreprise: ' : 'Enterprise: '} {this.state.nomsociete}</p>
                              <p >{this.state.lng ==="FR" ? 'Utilisateur:' : 'User: '} {this.state.nomutilisateur}</p>
                              <p ><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + this.state.mailuser}>{this.state.mailuser}</a> <br/> <br/><a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + this.state.telephoneutilisateur}>{this.state.telephoneutilisateur}</a> </p>
                            </div>

                            :

                            null

                          } 

                          <p style={{textAlign:'center', textDecoration:'none', fontWeight:"bold"}}>{parseFloat(this.state.amount).toFixed(2)} €</p>
                          
                          <div style={{textAlign:'center', textDecoration:'none'}}>
                            
                              {parseInt(this.state.idtypeuser) === 2 ?

                              <div>

                                <a style={{ textDecoration:'none'}} href={"tel:" + this.state.telephoneutilisateur}><span><CallIcon sx={{ width: '50px', height:'50px' }} style={{color:"#198754", marginRight:"10%"}}/></span></a>
                                <a style={{textDecoration:'none'}} href={"mailto:" + this.state.mailuser}><span><MailOutlineIcon sx={{ width: '50px', height:'50px' }} style={{color:"#2A83E8", marginRight:"10%"}}/></span></a>
                                <a href={this.state.wbslink+"bill/Facture_UNSAID_"+this.state.paypalid+".pdf"} download><span><PictureAsPdfIcon sx={{ width: '50px', height:'50px' }} style={{color:"#dc3545"}}/></span> </a>

                              </div>

                              :

                              <a href={this.state.wbslink+"bill/Facture_UNSAID_"+this.state.paypalid+".pdf"} download><span><PictureAsPdfIcon sx={{ width: '50px', height:'50px' }} style={{color:"#dc3545"}}/></span> </a>

                              } 
                          
                          </div>
                                                                                             
                        </div>

                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }

                  <div className='centrervue' >

                  <input className="inputfind" style={{marginTop: "0px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>
                  
                  <BrowserView>
               
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>

                          <TableCell >{this.state.lng ==="FR" ? 'Date' : 'Date'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Entreprise' : 'Company'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Utilisateur' : 'User'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Offre' : 'Offer'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Montant' : 'Amount'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Facture' : 'Bill'}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listpayment.map((row) => (
                          <TableRow key={row.idpayment}>

                            <TableCell >{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}s</TableCell>
                            <TableCell >{row.nomsociete}</TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.name}<br/><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>Mail: {row.email}</a> <br/> <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephoneutilisateur}>Tél: {row.telephoneutilisateur}</a></TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.offertype.charAt(0).toUpperCase() + row.offertype.slice(1)}</TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.priceht} €</TableCell>
                            <TableCell style={{textAlign:"payment"}}><a href={this.state.wbslink+"bill/Facture_UNSAID_"+row.paypalid+".pdf"} download><span><PictureAsPdfIcon sx={{ width: '40px', height:'40px' }} style={{color:"#dc3545"}}/></span> </a></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {this.state.listpayment.length>0?

                  <ReactPaginate
                  previousLabel={"<< prev"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={"next >>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  }

                  </BrowserView>

                  <MobileView>

                  {/*
                  <div className='paginationmobile' style={{marginTop:"-5%"}}>

                    {this.state.listpayment.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }
                      

                    </div>
                   

                    <div  style={{marginTop:"5%"}}>
                      <p style={{color:"transparent"}}>.</p>
                    </div>

                     */}

                    <div >

                      {this.state.listpayment.map((row) => (

                      <Card style={{ width: '90%', margin:"5%",fontSize:"14px",textAlign:"left", marginTop:"-10px", borderWidth:"2px", borderColor:"#ebebeb", backgroundColor:"#ebebeb"}}>

                      <Card.Body  key={row.idpayment}>

                      {parseInt(this.state.idtypeuser) === 2 ?

                        <div className="d-flex flex-row">
                          <div style={{width:"80%"}}><p style={{color:"black", margin:"-10px"}}><span style={{fontWeight:"bold"}}>{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}s<br/></span><span style={{fontWeight:"bold", color:"#2A83E8"}}>ID: {row.paypalid}</span><br/>{row.nomsociete}<br/>{row.name}<br/>{row.offertype.charAt(0).toUpperCase() + row.offertype.slice(1)}</p></div>
                          <div style={{width:"20%", margin:"2%", textAlign:"center"}}><span onClick={() => this.infopayment(row.idpayment,row.paymentdate,row.mailuser,row.offertype,row.priceht,row.paypalid,row.nomutilisateur + " " + row.prenomsutilisateur, row.nomsociete,row.telephoneutilisateur)} style={{color:row.offertype.charAt(1) === "a"?'#CD7F32':'#2A83E8'}}><InfoIcon sx={{ width: '40px', height:'40px' }}/></span><br/><span style={{fontWeight:"bold", color:"#2A83E8"}}>{row.priceht} €</span></div>
                        </div>

                        :

                        <div className="d-flex flex-row" >
                          <div style={{width:"80%",lineHeight:"30px"}}><p style={{color:"black", margin:"-10px"}}><span style={{fontWeight:"bold"}}>{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}s<br/></span><span style={{fontWeight:"bold", color:"#2A83E8"}}>ID: {row.paypalid}</span><br/>{row.offertype.charAt(0).toUpperCase() + row.offertype.slice(1)}</p></div>
                          <div style={{width:"20%", textAlign:"center",top:0}}><span onClick={() => this.infopayment(row.idpayment,row.paymentdate,row.mailuser,row.offertype,row.priceht,row.paypalid,row.nomutilisateur + " " + row.prenomsutilisateur, row.nomsociete,row.telephoneutilisateur)} style={{color:row.offertype === "basic-monthly"?'#CD7F32':'#2A83E8'}}><InfoIcon sx={{ width: '30px', height:'30px' }}/></span><br/><span style={{fontWeight:"bold", color:"#2A83E8"}}>{row.priceht} €</span></div>
                        </div>

                      } 


                      </Card.Body>
                      </Card>

                      ))}

                    </div>

                  </MobileView>

                  </div> 
               
                </div>

                }

              </div>
        
          );
        }
    
}

export default Listpayment;
