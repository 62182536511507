import packageJson from '../../package.json';
import './moncss.css';
import React,  {Component} from 'react';
import { Nav,Button} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { isBrowser} from "react-device-detect";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import queryString from 'query-string';
import Loader from "react-js-loader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


var errocode = 0;

class Login extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
  
      selectedFile: null,
      dureesound:'',
      selectedOption: null,
      listsound: [],
      persons: [],
      speakers: [],
      selectedValue: "",
      diarizer: [],
      usertype:0,
      nom: '',
      prenoms: '',
      mail: '',
      motdepasse: '',
      societe: 0,
      telephone: '',
      poste: '',
      errorform: false,
      errormessage:'',
      signin : false,
      tokenuser:[],
      isModalthematique: false,
      isModalspeaker: false,
      speaker1: '',
      speaker2: '',
      idaudioselect: 0,
      thematique:'',
      show_input:'text',
      tokenexist: true,
      tokenvalue: '',
      reload: false,
      loadershow : false,
      textloading:'',
      wbslink:'',
      resetpassword:false,
      resetpasswordsuccess:false,
      showpassword : 'password',
    };

    this.selectusertype = this.selectusertype.bind(this);
    this.selectsociety = this.selectsociety.bind(this);
        
  }
    
    componentDidMount() {

       
    };

    componentWillMount() {

      sessionStorage.clear();

      let wbslinkload = packageJson.wbslink;

      this.setState({ wbslink:wbslinkload});

      this.setState({ lng:'EN'})


      //this.refreshdata();

    }

  

    refreshdata () {

      if (this.state.reload === false){

        window.location.reload(false);
        this.setState({ reload: true})


      }
            

    }

    //VERIFICATION MAIL ET MOT DE PASSE
    verifyuser () {

        if (this.state.mail === "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              this.setState({ errorform: true, errormessage: 'Mail invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.' });
                
                return;

            }
            
        }
        
        if (this.state.motdepasse === "") {

          this.setState({ errorform: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.' });
                            
                return;

        }

        this.setState({ loadershow: true });

        this.connexionuser()

  }; 


  //CONNEXION UTILISATEUR
  connexionuser(){

    if(this.state.mail === ""){
      if(this.state.lng === "FR"){
        this.setState({ errorform: true, errormessage: 'Veuillez saisir votre adresse mail.',loadershow: false });
        return;
      }else{
        this.setState({ errorform: true, errormessage: 'Please enter your email address.',loadershow: false });
        return;
      }  
    }

    if(this.state.motdepasse === ""){
      if(this.state.lng === "FR"){
        this.setState({ errorform: true, errormessage: 'Veuillez saisir votre mot de passe.',loadershow: false });
        return;
      }else{
        this.setState({ errorform: true, errormessage: 'Please enter your password.',loadershow: false });
        return;
      }  
    }

        // Create an object of formData 
        const formData = new FormData(); 

        formData.append("email",this.state.mail);
        formData.append("password",this.state.motdepasse);
    
        fetch(this.state.wbslink + `login`,{                  
  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            email:this.state.mail,
            password:this.state.motdepasse,
          })
    
        })
    
        .then(res => {
    
          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {



          sessionStorage.setItem( 
            'tokenunsaid', JSON.stringify(data.token).replace(/"/g, ""),
          );

          

          const myDecodedToken = decodeToken(JSON.stringify(data).replace(/"/g, ""));
    
          if(myDecodedToken == null){
            this.setState({ 
              tokenvalue: '',
              connected:false,
              admin: '',
              manager: '',
              user: '',
              lng:'',
            })
            this.setState({ typeuser: null})
            document.location.href = '/login';
            return;
          }
          const isMyTokenExpired = isExpired(JSON.stringify(data).replace(/"/g, ""));

          if(isMyTokenExpired === true){
            this.setState({ 
              tokenvalue: '',
              connected:false,
              admin: '',
              manager: '',
              user: '',
              lng:'',
            })
            this.setState({ typeuser: null})
            document.location.href = '/login';
            return;
          }

          const datauser = JSON.stringify(myDecodedToken.init).replace(/"/g, "");

          var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
          var originaldata = bytes.toString(CryptoJS.enc.Utf8);
          var userdata  = originaldata.split('-');

          sessionStorage.setItem( 'langueuser', userdata[5]);

          window.dispatchEvent(new Event("storage"));

          if(JSON.stringify(data.rgpd).replace(/"/g, "") === "false"){

            document.location.href = '/Consent';

          }else{

            document.location.href = '/Transcription';

          }
            
      })
      .catch(err => {
    
        if(errocode == 401 || errocode == 0){
          
          sessionStorage.removeItem("tokenunsaid");
          document.location.href = "/login";
    
          return
    
        }
    
        alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))
    
        this.setState({
          loadershow: false
        })
    
      })
  }


  //AFFICHER CHAMP RESET PASSWORD
  resetpasswordshow(){

    this.setState({
      resetpassword: true,
    });  

  }

  //CACHER CHAMP RESET PASSWORD
  resetpasswordhide(){

    this.setState({
      loadershow: false,
      errorform: false,
      errormessage:'',      
      resetpassword: false,
      resetpasswordsuccess:false,
    });  

  }

  //RESET PASSWORD
  resetpassworduser(){

    if (this.state.mail == "" ) {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.mail)) {

        this.setState({ errorform: true, errormessage: 'Mail invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.' });
          
          return;

      }
      
  }

    this.setState({ loadershow: true});

    if(this.state.mail === ""){
      if(this.state.lng === "FR"){
        this.setState({ errorform: true, errormessage: 'Veuillez saisir votre adresse mail.',loadershow: false });
        return;
      }else{
        this.setState({ errorform: true, errormessage: 'Please enter your email address.',loadershow: false });
        return;
      }  
    }

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("mail",this.state.mail);

    fetch(this.state.wbslink + `resetpassword`,{                  

      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        mail:this.state.mail,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.status == "success" ){

      this.setState({
        loadershow: false,
        errorform: false,
        errormessage:'',      
        resetpassword: false,
        resetpasswordsuccess:false,
      });  

      if(JSON.stringify(data.langue).replace(/"/g, "")=== "FR"){
  
        alert("Vous allez recevoir un email pour réinitialiser votre mot de passe.")

        return;

      }else{

        alert("You will receive an email to reset your password.")
        return;

      }  

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/"/g, ""))

    this.setState({
      loadershow: false
    })

  })


  }


  //SELECTION TYPE UTILISATEUR
  selectusertype(e) {
    this.setState({ usertype: e.target.value });
  }


  //SELECTION SOCIETE
  selectsociety(e) {
    this.setState({ societe: e.target.value });
  }
   
  render() {

          return (

              <div> 

              {isBrowser?

                <div style={{marginTop:'0vh'}} className="d-flex flex-column justify-content-center loginstyle">
                  
                    <div className="container">
                      <div className="row">
                        <div className="col">

                          <p style={{color:"white",fontWeight:"bold", fontSize:"2.5em"}}>Metamorphose your Managers</p>
                          
                          <p style={{color:"white",fontWeight:"bold", fontSize:"2.5em"}}>with AI data driven decision</p>
                          
                        </div>
                        <div class="col">
                        

                        <div className='d-flex justify-content-center' style={{marginLeft:"20%"}}>
                          <div style={{width:isBrowser?'420px':'90%'}}>
                            <div className="form-box login">
                          <Zoom duration={2000}>
                          <div className="d-flex justify-content-center" >
                            <img src={process.env.PUBLIC_URL + './images/logo.png'} style={{width:"200px", height:"200px"}}/>
                          </div>
                          </Zoom>
                          <Fade Bottom cascade duration={3000}>
                          <div className="d-flex justify-content-center">
                              <div className="d-flex flex-column" >

                                

                                {this.state.resetpasswordsuccess === false ? 

                                  
                                  <div>

                                    <div className="input-box">
                                      <span className="icon">
                                      <MailIcon style={{color:"#2A83E8"}}/>
                                      </span>
                                      <input type="text" required aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})}/> <label>Login</label>
                                    </div>

                                      {this.state.resetpassword === false ?

                                        <div className="form-group" style={{margin:'5px'}}>

                                          {this.state.errorformpassword == true ? 
                                
                                          <Alert severity="error">
                                            <AlertTitle className='textombrenoir'>Erreur</AlertTitle>
                                            <p className='textombrenoir' >{this.state.errormessage}</p>
                                          </Alert>

                                          

                                          :

                                          null

                                        }
                                        
                                        <div className="input-box">
                                          {this.state.showpassword == "password" ? <span className="icon" onClick={() => this.setState({ showpassword: "text"})} ><VisibilityIcon style={{color:"#2A83E8"}}/></span> : <span className="icon" onClick={() => this.setState({ showpassword: "password"})} ><VisibilityOffIcon style={{color:"#2A83E8"}}/></span>}
                                          <input type={this.state.showpassword == "text" ? "text" : "password"}  value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} required /> <label>Password</label>
                                        </div>


                                          <div className="container">

                                            <div className="row">

                                              <div className="col" onClick={() => this.resetpasswordshow()}>
                                                <a className="d-flex justify-content-center" style={{color:"#2A83E8", marginTop:"15px", textDecoration:"none",fontSize:"14px"}}>{this.state.lng ==="FR" ? 'Mot de passe oublié' : 'Forgot password'}</a>
                                              </div>

                                              <div className="col">
                                                <div className="d-flex justify-content-center">
                                                  <Button className="btn" style={{ marginTop:'10px', width:'150px',fontSize:"14px"}} onClick={() => this.connexionuser()} >{this.state.lng ==="FR" ? 'Se connecter' : 'Sign in'}</Button>
                                                </div> 
                                              </div>

                                            </div>

                                          </div>

                                        </div>

                                        :

                                        <div className="container">

                                          <div className="row">

                                            <div className="col" onClick={() => this.resetpasswordhide()}>
                                              <a className="d-flex justify-content-center" style={{marginTop:"15px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Annuler' : 'Cancel'}</a>
                                            </div>

                                            <div className="col">
                                              <div className="d-flex justify-content-center">
                                                <Button className="btn" style={{ marginTop:'10px', width:'250px'}} onClick={() => this.resetpassworduser()} >{this.state.lng ==="FR" ? 'Réintialiser le mot de passe' : 'Reset password'}</Button>
                                              </div> 
                                            </div>
                                          
                                          </div>

                                        </div>
                                        
                                      }
                                      

                                      {this.state.errorform == true ? 

                                        <Alert severity="error">
                                          <AlertTitle>Error</AlertTitle>
                                          {this.state.errormessage}
                                        </Alert>

                                        :

                                        null

                                      }


                                      {this.state.loadershow == true ?
                                          

                                          <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                            

                                          :

                                          null

                                      }
                                  
                                  </div>

                                  

                                  :

                                  <div>

                                      <div>

                                        <p className="d-flex justify-content-center" style={{marginTop:"20px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Votre mot de passe a été réinitialisé. Vous allez recevoir un mail.' : 'Your password has been reset. You will receive an email.'}</p>

                                        <div className="d-flex justify-content-center">
                                          <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.resetpasswordhide()} >{this.state.lng ==="FR" ? 'Se connecter' : 'Sign in'}</Button>
                                        </div>
                                        
                                      </div> 

                                  </div>

                                }
                                                  
                
                                </div>

                              </div> 

                              <div className='d-flex flex-row justify-content-center' style={{marginTop:'30px'}}>
                                  
                                  <Fade left>
                                    <Nav.Link href="https://www.linkedin.com/company/unsaid-ai" target="_blank" ><LinkedInIcon style={{fontSize:'30', color: '#0077b5',marginBottom:6}}/></Nav.Link>
                                    <Nav.Link href="https://www.youtube.com/channel/UCA5BDGMytHfnK19bhAD_vVg" target="_blank"><YouTubeIcon style={{fontSize:'30', color: '#FF0000', marginBottom:6}}/></Nav.Link>    
                                  </Fade>
                                  <Nav.Link href="https://www.facebook.com/unsaidai"  target="_blank"><FacebookIcon style={{fontSize:'30' , color: '#316FF6', marginBottom:6}}/></Nav.Link>
                                  <Fade right>
                                    <Nav.Link href="https://www.instagram.com/unsaid_ai/" target="_blank"><img src={process.env.PUBLIC_URL + './images/Instagram.png'} style={{width:"22px", height:"22px"}}/></Nav.Link>
                                    <Nav.Link href="https://twitter.com/unsaid_ai" target="_blank"><img src={process.env.PUBLIC_URL + './images/twitter.png'} style={{width:"27px", height:"27px",marginTop:"1px"}}/></Nav.Link>
                                  </Fade>

                              </div>

                              </Fade>  

                              </div>

                              </div>

                              </div>

                              </div>
                            </div>

                    </div>

                    <div class="footerWrap">
                    
                        <div class="footer">
                          <Flip bottom>                    
                            <p style={{ color:'#162938',fontSize:"16px", textAlign:"center"}}>
                                Copyright &copy; {new Date().getFullYear()} {this.state.lng === "fr"?  "Tous les droits réservés à ": "All rights reserved to "}Unsaid.
                            </p>
                          </Flip>
                        </div>   
                          
                    </div>

                  </div>
              
                  :

                  <div>

                    <Fade Bottom cascade duration={3000}>
                          <div >
                              <div className="d-flex flex-column" style={{marginTop:"10%"}}>

                              <Zoom duration={2000}>
                                <div className="d-flex justify-content-center" >
                                  <img src={process.env.PUBLIC_URL + './images/logo.png'} style={{width:"200px", height:"200px"}}/>
                                </div>
                              </Zoom>

                              <p style={{color:"#2A83E8",fontWeight:"bold", fontSize:"20px", textAlign:"center"}}>Metamorphose your Managers</p>
                          
                              <p style={{color:"#2A83E8",fontWeight:"bold", fontSize:"20px", textAlign:"center"}}>with AI data driven decision</p>

                                {this.state.resetpasswordsuccess === false ? 

                                  
                                  <div style={{margin:"10px", marginTop:"-10px"}}>

                                    <div className="input-box">
                                      <span className="icon">
                                      <MailIcon style={{color:"#2A83E8"}}/>
                                      </span>
                                      <input type="text" required aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})}/> <label>Login</label>
                                    </div>

                                      {this.state.resetpassword === false ?

                                        <div className="form-group" style={{margin:'5px'}}>

                                          {this.state.errorformpassword == true ? 
                                
                                          <Alert severity="error">
                                            <AlertTitle className='textombrenoir'>Erreur</AlertTitle>
                                            <p className='textombrenoir' >{this.state.errormessage}</p>
                                          </Alert>

                                          

                                          :

                                          null

                                        }
                                        
                                        <div className="input-box" >
                                          {this.state.showpassword == "password" ? <span className="icon" onClick={() => this.setState({ showpassword: "text"})} ><VisibilityIcon style={{color:"#2A83E8"}}/></span> : <span className="icon" onClick={() => this.setState({ showpassword: "password"})} ><VisibilityOffIcon style={{color:"#2A83E8"}}/></span>}
                                          <input type={this.state.showpassword == "text" ? "text" : "password"}  value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} required  /> <label>Password</label>
                                        </div>


                                          <div className="container">

                                            <div className="row">

                                              <div className="col" onClick={() => this.resetpasswordshow()}>
                                                <a className="d-flex justify-content-center" style={{color:"#2A83E8", marginTop:"15px", textDecoration:"none",fontSize:"14px"}}>{this.state.lng ==="FR" ? 'Mot de passe oublié' : 'Forgot password'}</a>
                                              </div>

                                              <div className="col">
                                                <div className="d-flex justify-content-center">
                                                  <Button className="btn" style={{ marginTop:'10px', width:'150px',fontSize:"14px"}} onClick={() => this.connexionuser()} >{this.state.lng ==="FR" ? 'Se connecter' : 'Sign in'}</Button>
                                                </div> 
                                              </div>

                                            </div>

                                          </div>

                                        </div>

                                        :

                                        <div className="container">

                                          <div className="row">

                                            <div className="col" onClick={() => this.resetpasswordhide()}>
                                              <a className="d-flex justify-content-center" style={{marginTop:"15px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Annuler' : 'Cancel'}</a>
                                            </div>

                                            <div className="col">
                                              <div className="d-flex justify-content-center">
                                                <Button className="btn" style={{ marginTop:'10px', width:'250px'}} onClick={() => this.resetpassworduser()} >{this.state.lng ==="FR" ? 'Réintialiser le mot de passe' : 'Reset password'}</Button>
                                              </div> 
                                            </div>
                                          
                                          </div>

                                        </div>
                                        
                                      }
                                      

                                      {this.state.errorform == true ? 

                                        <Alert severity="error">
                                          <AlertTitle>Error</AlertTitle>
                                          {this.state.errormessage}
                                        </Alert>

                                        :

                                        null

                                      }


                                      {this.state.loadershow == true ?
                                          

                                          <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                            

                                          :

                                          null

                                      }
                                  
                                  </div>

                                  

                                  :

                                  <div>

                                      <div>

                                        <p className="d-flex justify-content-center" style={{marginTop:"20px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Votre mot de passe a été réinitialsé. Vous allez recevoir un mail.' : 'Your password has been reset. You will receive an email.'}</p>

                                        <div className="d-flex justify-content-center">
                                          <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.resetpasswordhide()} >{this.state.lng ==="FR" ? 'Se connecter' : 'Sign in'}</Button>
                                        </div>
                                        
                                      </div> 

                                  </div>

                                }
                                                  
                
                                </div>

                              </div> 

                              <div style={{marginTop:"10px"}}>

                                <div className='d-flex flex-row justify-content-center' >
                                    
                                    <Fade left>
                                      <Nav.Link href="https://www.linkedin.com/company/unsaid-ai" target="_blank" ><LinkedInIcon style={{fontSize:'30', color: '#0077b5',marginBottom:6}}/></Nav.Link>
                                      <Nav.Link href="https://www.youtube.com/channel/UCA5BDGMytHfnK19bhAD_vVg" target="_blank"><YouTubeIcon style={{fontSize:'30', color: '#FF0000', marginBottom:6}}/></Nav.Link>    
                                    </Fade>
                                    <Nav.Link href="https://www.facebook.com/unsaidai"  target="_blank"><FacebookIcon style={{fontSize:'30' , color: '#316FF6', marginBottom:6}}/></Nav.Link>
                                    <Fade right>
                                      <Nav.Link href="https://www.instagram.com/unsaid_ai/" target="_blank"><img src={process.env.PUBLIC_URL + './images/Instagram.png'} style={{width:"22px", height:"22px"}}/></Nav.Link>
                                      <Nav.Link href="https://twitter.com/unsaid_ai" target="_blank"><img src={process.env.PUBLIC_URL + './images/twitter.png'} style={{width:"27px", height:"27px",marginTop:"0px"}}/></Nav.Link>
                                    </Fade>

                                </div>

                      
                                <div className='d-flex flex-row justify-content-center' >
                                  <Flip bottom>                    
                                    <p style={{ color:'#162938',fontSize:"16px", textAlign:"center"}}>
                                        Copyright &copy; {new Date().getFullYear()} {this.state.lng === "fr"?  "Tous les droits réservés à ": "All rights reserved to "}Unsaid.
                                    </p>
                                  </Flip>
                                </div>   

                              </div>

                              


                              </Fade>  

                  </div>
                
              }

          </div>
                          

          );

        }
    
}

export default Login;
